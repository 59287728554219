<div class="container"> 
  <div class="add-stream-modal">
    <div class="flex justify-content-between heade-title">
      <div class="flex align-items-center"> 
        <h3>
          <a [routerLink]="['/stream/my-live-stream']"><img src="../../../../../assets/images/arrow-left.svg" alt="" /></a> &nbsp; {{ streamID ? "Edit Live Stream" : "Create Live Stream"}}
        </h3>
      </div>
    </div> 
    <div class="inner-wrapper" [ngStyle]="{ 'overflow' : filteredFruits?.length > 0 ? 'hidden': ''}">
      <div class="flex align-items-flex-start upload-wrapper">
        <div class="flex justify-content-center align-items-center upload" *ngIf="!isAddStream">
          <label>
            <img src="../../../../../assets/images/add-img.svg" />
          </label>
        </div>
        <div class="flex justify-content-center align-items-center upload" *ngIf="isAddStream">
          <!-- <img *ngIf="imageData" [src]="imageData"> -->
          <!-- <advanced-image *ngIf="urlImage" [cldImg]="urlImage"></advanced-image> -->
          <advanced-video [cldVid]="videoUrlCld" controls></advanced-video>
        </div>
        <div class="right-part">
          <div class="flex align-items-center">         
            <label for="imglabel">
              <h4 class="title" *ngIf="!isAddStream">Add Stream Thumbnail Video</h4>
              <h4 class="title" *ngIf="isAddStream">Update Stream Thumbnail Video</h4>
            </label>
            <input type="file" accept="video/mp4,video/x-m4v,video/*" style="display: none" id="imglabel" class="control-input" 
              (change)="uploadImage($event)" #imglabel />
          </div>
          <p class="text">Must be MP4 or WMV and cannot exceed 5MB.</p>
        </div>
      </div>
       <span class="text-danger" *ngIf="isImage">{{imageRequired}}</span>
  
      <div class="form-container">
        <form [formGroup]="scheduleFormGroup">
          <div class="inner-form">
            <div class="form-wrapper">
              <div class="form-col stream-title">
                <label>Stream Title*</label>
                <input type="text" class="control-input" placeholder="Enter Stream Title" (keypress)="numericAlphaOnly($event)" formControlName="title" required/>
                <span *ngIf = "scheduleFormGroup.controls.title.touched">
                  <span class="text-danger" *ngIf="scheduleFormGroup.controls.title.errors?.['required']">
                    Title is required*
                  </span>
                  <span class="text-danger" *ngIf="scheduleFormGroup.controls.title.errors?.['minlength']">
                    The title must be at least three characters long.
                  </span>
                  <span class="text-danger" *ngIf="scheduleFormGroup.controls.title.errors?.['maxlength']">
                    The title cannot exceed 100 characters.
                  </span>
                </span>
              </div>
             
            </div>
            <div class="date-time form-wrapper ">
              <div class="form-col select-cat" *ngIf="allCategories && allCategories.length > 0">
                <label>Primary Category*</label>
                <ng-select class="control-input" [clearable]="false" [searchable]="false" placeholder="Select Primary Category" formControlName="category" (change)="onPrimaryCategoryChange($event)" required>
                  <ng-option *ngFor="let cat of parentCategories; trackBy: trackItem" [value]="cat.categoryId">
                    {{ cat.name }}
                  </ng-option>
                </ng-select>
                <span class="text-danger" *ngIf="scheduleFormGroup.controls.category.errors?.['required'] && scheduleFormGroup.controls.category.touched">
                  Category is required*
                </span>
              </div>
              <div class="form-col select-cat">
                <label>Secondary Category*</label>
                <ng-select class="control-input select-cat-2" [clearable]="false" [searchable]="false" placeholder="Select Secondary Category" formControlName="secondaryCategory" required>
                  <ng-option *ngFor="let cat1 of secondaryCategories; trackBy: trackItem" [value]="cat1.categoryId">
                    {{ cat1.name }}
                  </ng-option>
                </ng-select>
                <span class="text-danger" *ngIf="scheduleFormGroup.controls['secondaryCategory'].hasError['required'] && scheduleFormGroup.controls.secondaryCategory.touched">
                  Secondary Category is required*
                </span>
              </div>
            </div> 
            <div class="form-wrapper">
              <div class="form-col select-cat menus">
                <div class="label-wrapper">
                  <label>Products or Product Menu</label>
                  <span class="text"> Add products that you would like to have attached to your stream as you go live.</span>
                </div>
                <ng-select
                  formControlName="productsAndMenus"
                  [items]="productsOptions"
                  groupBy="type"
                  placeholder="Select Products or Product Menu"
                  [multiple]="true"
                  [closeOnSelect]="false" 
                  [searchable]="false"
                  [compareWith]="compareFn"
                  (change)="onMenuSelectionChange($event)"
                >
                  <ng-template ng-option-tmp let-item="item" let-disabled="disabled">
                    <div [class.disabled]="item.disabled">
                      <img
                        class="product-item-icon"
                        alt="item-icon"
                        [src]="item.type === 'product' ? 'assets/images/our-product-icon.svg' : 'assets/images/aside-icon10.svg'"
                      >
                      <span>{{ item.type === 'product' ? item.item.name : item.item.listName }}</span>
                    </div>
                  </ng-template>

                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <div class="ng-value label">
                      <span aria-hidden="true" class="ng-value-icon left ng-star-inserted" (click)="clear(item)">×</span>
                      <img
                        class="product-item-icon"
                        alt="item-icon"
                        [src]="item.type === 'product' ? 'assets/images/our-product-icon.svg' : 'assets/images/aside-icon10.svg'"
                      >
                      <span class="option">{{ item.type === 'product' ? item.item.name : item.item.listName  }}</span>
                    </div>
                  </ng-template>

                  <ng-template ng-optgroup-tmp let-item="item">
                    <div>{{ item.type === 'product' ? 'Products' : 'Menus' }}</div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="form-wrapper">
              <div class="form-col date-time">
                <label>Date & Time*</label>
                <div class="date-wrap">
                  <input class="control-input" [owlDateTime]="dt1" formControlName="date" placeholder="Select Date Time" [min]="streamID ? null : minDate" required/>
                  <span [owlDateTimeTrigger]="dt1" class="icon"><img src="../../../../../assets/images/time-date.svg" alt=""></span>
                </div>
                <owl-date-time [hour12Timer]="true" #dt1 panelClass="app-add-stream-time-selection"></owl-date-time>
                <span class="text-danger" *ngIf=" scheduleFormGroup.controls['date'].hasError('required') && scheduleFormGroup.controls.date.touched">
                  DateTime is required*
                </span>
              </div>
            </div>
            <div class="form-wrapper">
              <div class="form-col modrator-wrapper">
                <label>Moderator</label>
              <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="let fruit of fruits"
                  [selectable]="selectable"
                  [removable]="true"
                  (removed)="remove(fruit)">
                  {{fruit}}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input class="control-input" 
                  placeholder="Enter..."
                  #fruitInput
                  [formControl]="fruitCtrl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event)"
                  (keyup)="selectedValueChange($event)">
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let fruit of filteredFruits" [value]="fruit.Id">
                  {{fruit.userName}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
            <div class="form-wrapper">
              <div class="description-wrapper">
                <label>
                  Stream Description* 
                  <span class="max-word">
                    Maximum 500 words
                  </span>
                </label>
                <textarea type="text" class="description" placeholder="Enter Description" formControlName="description" cols="30" rows="7"></textarea>
                <span *ngIf="scheduleFormGroup.controls.description.touched">
                  <span class="text-danger" *ngIf="scheduleFormGroup.controls['description'].hasError('required')">
                    Description is required*
                  </span>
                  <span class="text-danger" *ngIf="scheduleFormGroup.controls.description.errors?.['minlength']">
                    The description must be at least three characters long.
                  </span>
                  <span class="text-danger" *ngIf="scheduleFormGroup.controls.description.errors?.['maxlength']">
                    The description cannot exceed 500 characters.
                  </span>
                </span>
              </div>
            </div>
            <div class="content-setting">
                <h4 class="title">Content Settings</h4>
              <div class="inner-content flex justify-content-between">
                <div class="left-part">
                  <h5>Explicit Content</h5>
                  <p class="description">
                    Turn this on if your stream contains explicit content.
                    <a href="">Learn More</a>
                  </p>
                </div>
                <div class="right-part">
                  <label class="switch">
                    <input type="checkbox" class="control-input" [checked]="isClick" (change)="toggleChange($event)" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
           
          </div>
          <div class="form-action flex items-center">
            <button type="submit" class="btn border-btn cancel" [routerLink]="['/stream/my-live-stream']">
              Cancel
            </button>
            <button type="submit" class="btn active-btn" [disabled]="!scheduleFormGroup.valid" (click)="onSubmit()">
              {{ streamID ? 'Update Stream Details' : 'Save Stream Details'}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  </div>
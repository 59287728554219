<div *ngIf="!noInternetConnection else noInternetConnectionTemplate ">
    <app-global-loader></app-global-loader>
    <router-outlet></router-outlet>
</div>

<ng-template #noInternetConnectionTemplate=>
    <div class="nointernet" *ngIf="noInternetConnection">
        <img src="assets/imgs/nointernet.svg" alt="">
        <h2>Oops!</h2>
        <p>No Internet connection found. <br> Check your connection or try again</p>
    </div>
</ng-template>
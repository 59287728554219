<div class="wrapper">
    <div class="close-button" (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <path id="a" d="M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7z"/>
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(5 5)">
                <mask id="b" fill="#fff">
                    <use xlink:href="#a"/>
                </mask>
                <g fill="#000" fill-opacity=".54" mask="url(#b)">
                    <path d="M-5-5h24v24H-5z"/>
                </g>
            </g>
        </svg>
    </div>
    <div class="title">What do you want to do with {{ data?.product?.name }} product?</div>
    <div class="buttons">
        <button type="button" class="btn active-btn" (click)="deleteFromTheList()">Remove form the {{ data?.menu?.listName }} menu</button>
        <button type="button" class="btn border-btn cancel" (click)="inactivate()">Inactivate</button>
    </div>
</div>
<div class="our-products">
    <div class="container">
        <div class="head-section flex">
            <h3 class="text-title">Help Center</h3>
            <div class="right flex"> 
                <div class="Search">
                    <input type="search" (keyup.enter)="searchTicket(iSearch.value)" [(ngModel)]="keyword" id="iSearch" #iSearch placeholder="Search by ticket, subject">
                    <button class="search-btn" (click)="searchTicket(iSearch.value)">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="icon" d="M14.5001 13.7931L10.7241 10.0171C11.6315 8.92772 12.0839 7.53048 11.9874 6.11601C11.8908 4.70154 11.2527 3.37875 10.2057 2.42282C9.15867 1.46688 7.78343 0.951395 6.36604 0.983601C4.94864 1.01581 3.59823 1.59322 2.59573 2.59573C1.59322 3.59823 1.01581 4.94864 0.983601 6.36604C0.951395 7.78343 1.46688 9.15867 2.42282 10.2057C3.37875 11.2527 4.70154 11.8908 6.11601 11.9874C7.53048 12.0839 8.92772 11.6315 10.0171 10.7241L13.7931 14.5001L14.5001 13.7931ZM2.00006 6.50006C2.00006 5.61004 2.26398 4.74001 2.75844 3.99999C3.25291 3.25997 3.95572 2.68319 4.77798 2.3426C5.60025 2.00201 6.50505 1.91289 7.37796 2.08652C8.25088 2.26016 9.0527 2.68874 9.68204 3.31808C10.3114 3.94741 10.74 4.74924 10.9136 5.62215C11.0872 6.49507 10.9981 7.39987 10.6575 8.22213C10.3169 9.0444 9.74015 9.74721 9.00012 10.2417C8.2601 10.7361 7.39007 11.0001 6.50006 11.0001C5.30699 10.9987 4.16317 10.5242 3.31954 9.68058C2.47591 8.83695 2.00138 7.69313 2.00006 6.50006Z" fill="#5C646C"></path>
                        </svg>
                    </button>
                </div>
                <div class="date-wrapper">
                    <mat-form-field appearance="fill" class="example-form-field">
                        <label class="label">Date Range</label>
                        <mat-date-range-input [rangePicker]="rangePicker">
                            <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
                            <input matEndDate placeholder="End date" [(ngModel)]="endDate">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #rangePicker>
                            <mat-date-range-picker-actions>
                                <button mat-raised-button color="primary" matDateRangePickerApply (click)="applyDateRangeFilter()">Apply</button>
                            </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </mat-form-field>
                </div>
               <div class="action-wrap">
                    <button class="btn btn-default clear" (click)="clearFilter()" *ngIf="keyword || startDate || endDate">
                        Clear Filter
                    </button>
                    <button class="primary-btn" (click)="raiseRequest()">+ New Request</button>
               </div>
            </div>
        </div>
        <div class="product-table">
            <table class="table-list">
                <thead>
                    <tr>
                        <th>Ticket No.</th>
                        <th>Subject</th>
                        <th>Status</th>
                        <th>Created Date</th>
                        <th>Updated Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let chat of conversations">
                        <td>
                            #{{chat.Id}}
                        </td>
                        <td>
                            {{chat.subject}}
                        </td>
                        <td class="chat newChat" *ngIf="chat.status === 1">
                            <span class="new"></span>
                            New
                        </td>
                        <td class="chat" *ngIf="chat.status === 2">
                            <span class="accepted"></span>
                            Accepted
                        </td>
                        <td class="chat" *ngIf="chat.status === 3">
                            <span class="inprogress"></span>
                            Inprogress
                        </td>
                        <td class="chat" *ngIf="chat.status === 4">
                            <span class="completed"></span>
                            Completed
                        </td>
                        <td>
                            {{chat.createdDate | date: 'MM/dd/yyyy'}}
                        </td>
                        <td>
                            {{chat.modifiedDate | date: 'MM/dd/yyyy'}}
                        </td>
                        <td style="cursor: pointer;">   
                            <div (click)="goToDetailsPage(chat)">
                                <i class="fa fa-eye pass-icon"></i> 
                                View
                            </div> 
                            <!-- <div *ngIf="chat.status === 4" (clcik)="reOpen(chat)">
                                <i class="fa fa-solid fa-rotate-right"></i>
                            </div> -->
                         </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="winner-wrapper">
    <div class="winner-content">
        <h4 class="title-text">winner is</h4>
        <div class="winner-cup">
            <img src="../../../../../../../assets/images/winner-cup.svg" alt="">
        </div>
        <div class="footer-content">
            <div class="profile-pic">
                <advanced-image [cldImg]="currentUserImage"></advanced-image>
                <img *ngIf="!currentUserImage" src="../../../../../../../assets/images/defaultProfilePic.png" style="max-width: 50%;">
            </div>
            <h3 class="text"> {{userData.customer_name}}</h3>
        </div>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-socialmedia',
  templateUrl: './socialmedia.component.html',
  styleUrls: ['./socialmedia.component.scss']
})

export class SocialmediaComponent implements OnInit {

  streamLink: string = '';
  id : any;
  uuid : any;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any, 
    public dialogRef: MatDialogRef<SocialmediaComponent>
  ) { }

  ngOnInit(): void {
    this.streamLink = environment.streamUrl + 'streaming?stream=' +this.data.id + '&uuid=' + this.data.uuid;
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
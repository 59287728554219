import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpCenterComponent } from './help-center.component';
import { HelpCenterApiService } from './help-center.service';
import { RaiseRequestComponent } from './raise-request/raise-request.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { CloudinaryModule } from "@cloudinary/ng";
import { RaiseRequestDetailsComponent } from './raise-request-details/raise-request-details.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export const routes = [
    {
        path: '',
        component: HelpCenterComponent,
        data: {
            title: 'Help Center',
        }
    },
    {
        path : 'help-center-details/:id',
        component : RaiseRequestDetailsComponent,
        data: {
            title: 'Help Center Details',
        }
    }
];

@NgModule({
    declarations: [
        HelpCenterComponent,
        RaiseRequestComponent,
        RaiseRequestDetailsComponent
    ],
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      SharedModule,
      FormsModule,
      ReactiveFormsModule,
      MatDialogModule,
      NgSelectModule,
      CloudinaryModule,
      NgxSummernoteModule,
    ],
    providers: [
        HelpCenterApiService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    ]
})

export class HelpCenterModule { }  
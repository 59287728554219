import { Component, EventEmitter, Output, Input, OnChanges } from '@angular/core';

interface IPager {
    totalItems: number;
    currentPage: number;
    pageSize: number,
    totalPages: number,
    startPage: number,
    endPage: number,
    startIndex: number,
    endIndex: number,
    pages: number[]
};

@Component({
    selector: 'app-pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnChanges {

    pager: any = {};
    @Input() counts: any;
    @Input() currentPage: any;
    @Input() pageSize: any;

    @Output() pageChange = new EventEmitter<number>();

    ngOnChanges() {
        if (this.counts > 0) {
            this.setPage(this.currentPage || 1);
        } else if (this.counts === 0) {
            this.pager = {};
        }
    }

    pageClicked(page) {
        if (page < 1 || page > this.pager.totalPages || page === this.pager.currentPage) {
            return;
        }
        this.pageChange.emit(page);
        this.setPage(page);
    }

    setPage(page: number): void {
        if (page < 1 || page > this.pager.totalPages) {
            return;
        }
        this.pager = this.getPager(this.counts, page, this.pageSize);
    }

    getPager(totalItems: number, currentPage: number, pageSize: number): IPager {

        const totalPages = Math.ceil(totalItems / pageSize);
        let startPage: number, endPage: number;

        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 1 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        const pages = this.generatePages(startPage, endPage + 1);

        if (totalPages < this.pager.totalPages) {
            if (currentPage > totalPages) {
                currentPage = currentPage - 1;
            }
            this.pageChange.emit(currentPage);
        }

        return {
            totalItems,
            currentPage,
            pageSize,
            totalPages,
            startPage,
            endPage,
            startIndex,
            endIndex,
            pages
        };
    }

    chooseClick(select): void {
        if (select === this.pager.currentPage) {
            return;
        }
        this.setPage(select);
        this.pageChange.emit(this.pager.currentPage);
    }

    private generatePages(start: number, end: number): number[] {
        const result: number[] = [];
    
        if (end === undefined) {
            end = start;
            start = 0;
        }

        for (let i = start; i < end; i += 1) {
            result.push(i);
        }
    
        return result;
    }
}

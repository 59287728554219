import { AuthSandbox } from './../../../../core/auth/auth.sandbox';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonSandbox } from '../../../../core/common/common.sandbox';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../../../core/common/common.service';
import { Cloudinary } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { IActionDropdownOption } from '../actions-dropdown/interfaces';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})

export class CommonHeaderComponent implements OnInit {
  actions: IActionDropdownOption[] = [
    {
      icon: '',
      title: 'Profile',
      actionType: 'profile'
    },
    {
      icon: '',
      title: 'Logout',
      actionType: 'logout'
    }
  ];

  public userDetails = JSON.parse(localStorage.getItem('vendorUserDetails'));
  public userProfileDetails: any;
  public imageUrl = environment.imageUrl;
  token: any;
  isActive : boolean = true;
  public cloudName = environment.cloudName;
  public cloudFolder = environment.cloudFolder;
  cld: any;
  urlImage : any;
  imageName : any;
  currentFolder : any;
  public profileUrl = environment.storeProfileUrl;
  currentUser: any;

  @Input() isMobile;
  @Input() isDesktopDevice;
  userDetails1 : any;
  logoImage : any;

  constructor(
    public router: Router, 
    public commonSandbox: CommonSandbox, 
    public sandbox: AuthSandbox,
    public service : CommonService
  )
  {
    this.currentUser = localStorage.getItem('vendorToken');
    this.service.isClose.subscribe((result) => {
      if(result) {
        this.isActive = result;
      }
    })
    this.userDetails1 = JSON.parse(localStorage.getItem('vendor-settings'));
      if(this.userDetails1) {
        if(this.userDetails1.cloudImageUrl && this.userDetails1.cloudImageFolder &&  this.userDetails1.storeLogoPath && this.userDetails1.storeLogo) {
          this.logoImage = this.userDetails1.cloudImageUrl + '' + this.userDetails1.cloudImageFolder + "/" + this.userDetails1.storeLogoPath  + "/"+  this.userDetails1.storeLogo;
        }
      }
  }

  ngOnInit() {
    this.cld = new Cloudinary({
      cloud: {
        cloudName: this.cloudName,
      }
    });
    this.getVendorProfile();
    this.getSettings();
    this.commonSandbox.getProfile$.subscribe(profile => {
      if (profile) {
        this.userProfileDetails = profile.customerDetail;
        if(this.userProfileDetails.avatarPath && this.userProfileDetails.avatar) {
          this.urlImage = this.cld.image(this.cloudFolder + "/"+ this.userProfileDetails.avatarPath + "/" + this.userProfileDetails.avatar);
          this.urlImage.resize(fill().width(88).height(125)).border(byRadius(8));
        }
      }
    });
  }

  getVendorProfile() {
    this.commonSandbox.doGetProfile();
  }

  getSettings() {
    this.commonSandbox.doSettings();
  }

  colorPalette(color) {
    localStorage.setItem('colorPalette', color);
  }

  openSidemenu() {
    this.service.setValue = 'isOpen';
    this.isActive = false;
  }

  closeSideMenu() {
    this.isActive = true;
    this.service.setValue = '';
  }

  /**
  * @method -- goto Profile
  */
  private gotoProfile() {
    const encoded = encodeURI(this.currentUser);
    window.open(this.profileUrl + "?auth=" + encoded, '_blank');
  }

  private logout() {
    this.sandbox.doLogout({});
    localStorage.clear();
    localStorage.removeItem('vendorToken');
    localStorage.removeItem('vendorUserDetails');
    localStorage.removeItem('vendorUser');
    localStorage.removeItem('vendor-settings');    
    localStorage.removeItem('currentStreamDetails');
    localStorage.removeItem('clickedLink');
    this.router.navigate(['/auth/login']);
  }

  onAction(action: IActionDropdownOption): void {
    const actionType = action.actionType;
    if (actionType === 'profile') {
      this.gotoProfile();
      return;
    }

    if (actionType === 'logout') {
      this.logout();
    }
  }
}
import { Component, Inject } from "@angular/core";
import { IProductMenu } from '../../../pages/component/our-products/product-menus/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductMenusService } from '../../../pages/component/our-products/product-menus/services/product-menus.service';
import { ProductsService } from '../../services/products.service';
import { IStreamProduct } from '../../../pages/component/stream/interfaces';


@Component({
  selector: "confirm-delete-product-from-list",
  templateUrl: "./confirm-delete-product-from-list.component.html",
  styleUrls: ["./confirm-delete-product-from-list.component.scss"],
})

export class ConfirmDeleteProductFromList {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {menu: IProductMenu, product: IStreamProduct},
    private dialogRef: MatDialogRef<ConfirmDeleteProductFromList>,
    private productsMenusService: ProductMenusService,
    private productsService: ProductsService
  ) {}

  close(isDeleteProduct = false): void {
    this.dialogRef.close(isDeleteProduct ? this.data.product.product_id : null);
  }

  inactivate(): void {
    this.productsService.updateStatus({
      productId: this.data.product.product_id,
      status: 0
    }).subscribe(() => this.close(true))
  }

  deleteFromTheList(): void {
    this.productsMenusService.changeProducts(
      this.data.menu.productListId,
      [Number(this.data.product.product_id)],
      'remove'
    ).subscribe(() => this.close(true))
  }
}
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PayoutService } from '../payout.service';

@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.scss']
})

export class AddBankAccountComponent implements OnInit {

  addBankFormGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public dialogRef: MatDialogRef<AddBankAccountComponent>,
    public service : PayoutService,
  ) { }

  ngOnInit() {
    this.addBankFormGroup = new FormGroup({
      bankName : new FormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(25)]),
      accNumber : new FormControl("", [Validators.required, Validators.minLength(7), Validators.maxLength(15)]),   
      routingNumber : new FormControl("",[Validators.required, Validators.minLength(9), Validators.maxLength(9)]),
    })
  }

  /**
  * @method closeModal() - Its use for closeing Modal()
  */
  closeModal() {
    this.dialogRef.close();
  }

  /**
   * @method -- check alphabet or not
   * @param event 
   * @returns 
   */
  checkAlphabet(event) {
    if ((event.keyCode > 64 && event.keyCode < 91) || (event.keyCode > 96 && event.keyCode < 123) || (event.keyCode == 8) || event.keyCode == 32) {
      return true;
    }
    else  {
      return false;
    }
  }

  /**
  * @method numericAlphaOnly
  * @param event 
  * @returns 
  */
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } 
    return true;
  }

  /**
   * @method -- add bank account
   */
  addBankAccount() {
    let param : any = {
      accountId : this.data.accountId,
      external_account_type : 'bank',
      bankName : this.addBankFormGroup.value.bankName,
      routing_number :  this.addBankFormGroup.value.routingNumber,
      account_number : this.addBankFormGroup.value.accNumber
    }

    this.service.addExternalAccountAndCard(param).subscribe((result : any) => {
      if(result.status === 1 && result.result) {
        this.dialogRef.close(result);
      }
    })
  }
}
<div class="blocked-wrapper">
    <h5 class="text-title">
        Blocked User(s)
        <button type="button" (click)="blockUser()" class="primary-btn" style="float: right;" *ngIf="blocked">Block this user</button>
    </h5>
    <div class="blocked-list items-center flex">
            <ng-container *ngFor="let user of blockedUsers">
                <div class="list-name" *ngIf="user.is_blocked === 1">
                    <h6>{{user.blocked_username}}</h6>
                </div>
            </ng-container>
    
    </div>
</div> 

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';
import { EditShipmentComponent } from './edit-shipment/edit-shipment.component';
import { ShipmentsService } from './shipments.service';

export const routes = [
    {
        path: '',
        component: ShipmentListComponent,
        data: {
            title: 'Shipments'
        }
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        SharedModule,
        FormsModule,
        NgbModule,
        MatInputModule,
        MatStepperModule,
        MatIconModule,
        InfiniteScrollModule,
        NgSelectModule
    ],
    declarations: [
        ShipmentListComponent,
        EditShipmentComponent
    ],
    providers: [
        ShipmentsService
    ]

})
export class ShipmentsModule {
}

<mat-card>
  <mat-card fxLayout="row wrap" class="containerDiv">
      <div class="w-100">
        <h2 class="text-muted text-center">Schedule Stream</h2>
        <form [formGroup]="scheduleFormGroup" (ngSubmit)="onSubmit()">
          <mat-form-field class="w-100 mt-2">
            <input matInput placeholder="Show Title" formControlName="title" required>
            <mat-error *ngIf="scheduleFormGroup.controls['title'].hasError('required')">Title is required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100 mt-2">
            <input matInput placeholder="Show Description" formControlName="description">
          </mat-form-field>
         <div class="dateTime">
          <mat-form-field class="w-45 mt-2" style="width: 45%;">
            <input matInput [matDatepicker]="picker" placeholder="Date" formControlName="date" [min]="minDate" readonly required>
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="scheduleFormGroup.controls['date'].hasError('required')">Date is required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-45 mt-2" style="width:45%">
            <input matInput type="time" formControlName="time" placeholder= "Time" [min]="minDate" required>
            <!-- <mat-icon matSuffix style="cursor: pointer;">access_time</mat-icon> -->
            <mat-error *ngIf="scheduleFormGroup.controls['time'].hasError('required')">Time is required</mat-error>
          </mat-form-field>

         </div>
          <mat-form-field class="w-100 mt-2" *ngIf="allCategories && allCategories.length > 0">
            <mat-select matSelect formControlName="category" [(ngModel)]="selectedCategory" placeholder= "My Primary Category" (selectionChange)="onPrimaryCategoryChange($event)" required>
                <mat-option *ngFor="let cat of parentCategories; trackBy: trackItem" [value]="cat.categoryId">{{cat.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="scheduleFormGroup.controls['category'].hasError('required')">Category is required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100 mt-2" *ngIf="scheduleFormGroup.controls['category'].value !== ''">
            <mat-select matSelect formControlName="secondaryCategory" [(ngModel)]="selectedSecondaryCategory" placeholder= "My Secondary Category" required>
              <mat-option *ngFor="let cat1 of secondaryCategories; trackBy: trackItem" [value]="cat1.categoryId">{{cat1.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="scheduleFormGroup.controls['secondaryCategory'].hasError('required')">Secondary Category is required</mat-error>
          </mat-form-field>
          <mat-divider [inset]="true"></mat-divider>
          <h3 style="margin-top: 20px;"><strong>Content Settings</strong></h3>
          <div class="w-100 mt-2 toggle-wrapper">
            <mat-slide-toggle (change)="toggleChange($event)" color="primary">Explicit Content</mat-slide-toggle>
            <small style="width: 100%;display: block;">Turn this on if your stream contains explicit content &nbsp; <a href="#">Learn More</a></small>
          </div>
          <div class="w-100 mt-3 btn">
            <button mat-raised-button style="margin-right: 20px;" type="button" routerLink="/stream/my-live-stream">Cancel</button>
            <button mat-raised-button color="primary" type="submit">Schedule</button>
          </div>
        </form>
      </div>
    </mat-card>
</mat-card>
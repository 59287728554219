import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HelpCenterApiService } from '../help-center.service';

@Component({
  selector: 'app-raise-request',
  templateUrl: './raise-request.component.html',
  styleUrls: ['./raise-request.component.scss']
})
export class RaiseRequestComponent implements OnInit {

  subjects: any[] = [];
  subjectObj: any;
  raiseRequestFormGroup: FormGroup;
  adminId: number;
  limit: number = 300;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public service: HelpCenterApiService, 
    public dialogRef: MatDialogRef<RaiseRequestComponent>, 
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.raiseRequestFormGroup = new FormGroup({
      subject: new FormControl("", [Validators.required]),
      message: new FormControl("", [Validators.required])
    });
    this.getAllCategories();
    this.adminId = this.data?.conversation?.recieverId || 49;
  }

  closeDialog(){
    this.dialogRef.close();
  }

  onSubmit() {
    Object.keys(this.raiseRequestFormGroup.controls).forEach((field) => {
      const control = this.raiseRequestFormGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if (this.raiseRequestFormGroup.status === "VALID") {
      let params = {
        "category" : this.subjects.filter((item)=> item.Id === this.subjectObj)[0].categoryName,
        "sender" : JSON.parse(localStorage.getItem('vendorUserDetails')).email,
        "senderId" : JSON.parse(localStorage.getItem('vendorUserDetails')).vendorId,
        "recieverId" : this.adminId,
        "reciever" : "admin",
        "message" : this.raiseRequestFormGroup.value.message,
        "senderType" : "seller",
        "categoryId" : this.subjectObj,
      };
      this.service.SendMessage(params).subscribe(data => { 
        if (data && data.status === 1) {
          this.toastr.success(data.message);
          this.dialogRef.close(1);
        } else {
          this.toastr.error(data.message);
        }
      });

    }
  }

  getAllCategories() {
    this.service.AllCategories().subscribe((data) => {
      if (data && data.status === 1) {
        this.subjects = data.data;
      }
    });
  }

  limitChange(event) {
    this.limit = 300 - event.target.value.length;
  }
}

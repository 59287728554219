import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from '../../../core/common/common.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  setValue : string = '';
  deviceInfo : any;
  isDesktopDevice : boolean = true;
  isMobile : boolean = false;
  isTablet : boolean = false;

  constructor(
    public service : CommonService,
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {
    this.setValue = this.service.setValue;
    this.epicFunction();
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './default/common/layout/layout.component';
import { AuthGuard } from './core/providers/guards/auth-guard';
import {MaintenanceComponent} from './maintenance/maintenance.component';

const routes: Routes = [

  {
    path: 'auth',
    loadChildren: () => import('./default/auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard]
  },
  { path: 'maintenance', component: MaintenanceComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./default/pages/component/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          title: 'Dashboard'
        }
      },
      {
        path: 'our-products',
        loadChildren : () => import('./default/pages/component/our-products/our-products.module').then(m => m.OurProductsModule),
        data : {
          title : 'Our Products'
        }
      },
      {
        path: 'stream',
        loadChildren: () => import('./default/pages/component/stream/stream.module').then(m => m.StreamModule),
        data: {
          title: 'Live Streams'
        }
      },
      {
        path: 'orders',
        loadChildren: () => import('./default/pages/component/order/order.module').then(m => m.OrdersModule),
        data: {
          title: 'Orders'
        }
      },
      {
        path: 'shipments',
        loadChildren: () => import('./default/pages/component/shipments/shipments.module').then(m => m.ShipmentsModule),
        data: {
          title: 'Shipments'
        }
      },
      {
        path: 'payments',
        loadChildren: () => import('./default/pages/component/payments/payments.module').then(m => m.PaymentsModule),
        data: {
          title : 'Payments'
        }
      },
      {
        path : 'payout',
        loadChildren : () => import('./default/pages/component/payout/payout.module').then(m => m.PayoutModule),
        data : {
          title : 'Payout'
        }
      },
      {
        path: 'help-center',
        loadChildren: () => import('./default/pages/component/help-center/help-center.module').then(m => m.HelpCenterModule),
        data: {
          title : 'Help-Center'
        }
      },
      {
        path: 'card',
        loadChildren : () => import('./default/pages/component/card-details/card-details.module').then(m => m.CardDetailsModule),
        data : {
          title : 'Card Details'
        }
      },
      /* {
        path: 'products',
        loadChildren: () => import('./default/pages/component/products/products.module').then(m => m.ProductsModule),
      }, */
      /* {
        path: 'settings',
        loadChildren: () => import('./default/pages/component/settings/settings.module').then(m => m.SettingsModule),
        data: {
          title: 'Settings'
        }
      }, */
      // {
      //   path: 'ledger',
      //   loadChildren: () => import('./default/pages/component/ledger/ledger.module').then(m => m.LedgerModule),
      // },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash : true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayoutComponent } from './payout/payout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from '../../../../core/providers/interceptor/request.interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AddBankAccountComponent } from './add-bank-account/add-bank-account.component';
import { AddCardComponent } from './add-card/add-card.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputMaskDirectiveDirective } from './input-mask-directive.directive';

export const routes = [
  {
    path: 'payout',
    component: PayoutComponent,
      data: {
        title: 'Payout',
      }
    }  
  ];

@NgModule({
  declarations: [
    PayoutComponent,
    AddBankAccountComponent,
    AddCardComponent,
    InputMaskDirectiveDirective
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatIconModule,
    MatAutocompleteModule,
    MatCardModule,
    MatTabsModule,
    MatDialogModule,
    NgbModule,
    NgxIntlTelInputModule,
    NgSelectModule
  ],
  exports : [
    InputMaskDirectiveDirective
  ],
  providers: [
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: RequestInterceptor, 
      multi: true 
    },
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'en-US' 
    }
  ]
})

export class PayoutModule { }
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {ToastrService} from 'ngx-toastr';
import { StreamApiService } from "../../../../../core/stream/stream.service";
import { StreamSandbox } from "../../../../../core/stream/stream.sandbox";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-schedule-stream",
  templateUrl: "./schedule-stream.component.html",
  styleUrls: ["./schedule-stream.component.scss"],
})
export class ScheduleStreamComponent implements OnInit, OnDestroy {
  scheduleFormGroup: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  allCategories: any[] = [];
  parentCategories: any[] = [];
  secondaryCategories: any[] = [];
  explicitContent: boolean = false;
  minDate: Date = new Date();
  streamUuid: any = null;
  selectedCategory: any = null;
  selectedSecondaryCategory: any = null;

  queryParamsSubscription = Subscription.EMPTY;

  constructor(public streamSandbox: StreamSandbox, public streamService: StreamApiService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.streamUuid = params['streamUuid'];
      let param: any = {};
      param.streamUuid = this.streamUuid;
      this.streamService.getStream(param).subscribe(stream => {
        this.scheduleFormGroup.patchValue({
          title: stream.data.title, 
          description: stream.data.description,
          date: new Date(stream.data.scheduleDate),
          time: stream.data.scheduletime
        });
        this.selectedCategory = stream.data.category_id;
        this.secondaryCategories = this.allCategories.filter(
          (c) => c.parentInt == stream.data.category_id
        );
        this.selectedSecondaryCategory = stream.data.subCategory_id;
        this.explicitContent = stream.data.isExplicit;
      });
    });

    //this.stream = JSON.parse(this.route.snapshot.paramMap.get('stream'));

    this.scheduleFormGroup = new FormGroup({
      title: new FormControl("", [Validators.required]),
      description: new FormControl(),
      category: new FormControl("", [Validators.required]),
      secondaryCategory: new FormControl("", [Validators.required]),
      date: new FormControl("", [Validators.required]),
      time: new FormControl("", [Validators.required])
    });

    this.streamSandbox.getCategoryList();
    this.streamSandbox.categoryList$.subscribe((data) => {
      if (data) {
        this.allCategories = data;
        this.parentCategories = data.filter(this.isParentCategory);
      }
    });
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription.unsubscribe();
  }

  toggleChange(event: any): void {
    this.explicitContent = event.checked;
  }

  onSubmit() {
    Object.keys(this.scheduleFormGroup.controls).forEach((field) => {
      const control = this.scheduleFormGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if (this.scheduleFormGroup.status === "VALID") {
      if(this.streamUuid) {
        let params: any = {};
        params.title = this.scheduleFormGroup.value.title;
        params.description = this.scheduleFormGroup.value.description;
        params.category_id = this.scheduleFormGroup.value.category;
        params.subCategory_id = this.scheduleFormGroup.value.secondaryCategory;
        params.isExplicit = this.explicitContent;
        params.scheduleDate = (new Date(this.scheduleFormGroup.value.date)).toLocaleDateString();
        params.scheduletime = this.scheduleFormGroup.value.time;
        this.streamService.updateStream(params, '/stream/updateStream/' + this.streamUuid).subscribe(data => {
          if (data && data.status === 1) {
            this.toastr.success(data.message);
            this.router.navigate(['/stream/my-live-stream']);
          } else {
            this.toastr.error(data.message);
          }
        });
      }
      else {
        let params: any = {};
        params.title = this.scheduleFormGroup.value.title;
        params.description = this.scheduleFormGroup.value.description;
        params.category_id = this.scheduleFormGroup.value.category;
        params.subCategory_id = this.scheduleFormGroup.value.secondaryCategory;
        params.isExplicit = this.explicitContent;
        params.isMute = false;
        params.user_id = localStorage.getItem('vendorUserDetails') ? JSON.parse(localStorage.getItem('vendorUserDetails')).id : 0;
        params.scheduleDate = (new Date(this.scheduleFormGroup.value.date)).toLocaleDateString();
        params.scheduletime = this.scheduleFormGroup.value.time;
        params.moderator = "[]";

        this.streamSandbox.addStream(params);
        this.streamSandbox.addStream$.subscribe(data => {
          if (data && data.status === 1) {
            this.toastr.success(data.message);
            this.router.navigate(['/stream/my-live-stream']);
          } else {
            this.toastr.error(data.message);
          }
        });
      }
    }
  }

  onPrimaryCategoryChange(event) {
    const categoryId = event.value;
    this.secondaryCategories = this.allCategories.filter(
      (c) => c.parentInt == categoryId
    );
  }

  isParentCategory(value) {
    return value.parentInt === 0;
  }

  public trackItem(index: number, item: any) {
    return item.categoryId;
  }
}

<div class="helpcenter-wrapper">
    <div class="container">
        <div class="breadcrumb-section">
            <span style="cursor: pointer;" (click)="goToHelpCenter()">Help Center</span>
            <span>/</span> 
            <span class="active">#{{raiseId}}</span>
        </div>
        <div class="head-title">
            <button> 
                <img src="../../../../../assets/images/arrow-left.svg" alt="" (click)="goToHelpCenter()">
            </button>
            <span class="chat-id">#{{raiseId}}</span>
        </div>
        <div class="msg-wrapper">
            <div class="head-section">
                <button class="msg-icon">
                    <img src="../../../../../assets/images/msg-icon.svg" alt="msg-icon">
                </button>
                <div class="msg-details">
                    <div class="top-text">
                        {{raiseDetails?.subject}}
                    </div>
                    <ul class="bottom-text">
                        <li class="name">
                            {{raiseDetails?.firstName}} {{raiseDetails?.lastName}}
                        </li>
                        <li class="gmail">
                            @{{raiseDetails?.userName}}
                            <span class="active"></span></li>
                        <li class="time-wrp">
                            <span class="time">
                                {{raiseDetails?.modifiedDate | date: "shortTime"}}
                            </span> 
                            <span class="date">
                                {{raiseDetails?.modifiedDate | date: "shortDate"}}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="msg-container">
                <div class="chat-container" #scrollMe>
                    <ng-container *ngFor = "let message of raiseDetails?.messages">
                        <div class="chat-content" *ngIf="message.h_sender !== 'admin@kellton.com'">
                            <div class="left-part">
                                <div class="profile-img"> 
                                    <advanced-image  *ngIf="message?.urlImage" [cldImg]="message?.urlImage"></advanced-image>
                                    <img *ngIf="!message?.urlImage" src="../../../../../assets/images/sender-img.svg" alt="">
                                </div>
                            </div>
                            <div class="right-part">
                                <div class="chat-title">
                                    <div class="chat-name">
                                        {{message?.first_name}} {{message?.last_name}}
                                    </div>
                                    <div class="time">
                                    {{message?.h_created_date | date: "shortTime"}}
                                    </div>
                                </div>
                                <div class="refund">
                                    {{message?.h_category}}
                                </div>
                                <div class="description" [innerHTML]="message?.h_message"></div>
                                <!-- <div class="chat-image">
                                    <img src="../../../../../assets/images/chat-img.svg" alt="">
                                </div> -->
                            </div>
                        </div>
                        <div class="chat-content" *ngIf="message.h_sender === 'admin@kellton.com'">
                            <div class="left-part">
                                <div class="profile-img"> 
                                    <advanced-image  *ngIf="message?.adminUrlImage" [cldImg]="message?.adminUrlImage"></advanced-image>
                                    <img *ngIf="!message?.adminUrlImage" src="../../../../../assets/images/admin-img.svg" alt="">
                                </div>
                            </div>
                            <div class="right-part">
                                <div class="chat-title">
                                    <div class="chat-name">
                                        {{message?.admin_ufirst_name ? message?.admin_ufirst_name : 'Admin' }}
                                    </div>
                                    <div class="time">
                                      {{message?.h_created_date | date: "shortTime"}}
                                    </div>
                                </div>
                                <div class="refund">
                                    {{message?.h_category}}    
                                </div>
                                <div class="description" [innerHTML]="message?.h_message"></div>
                                <!-- <div class="chat-image">
                                    <img src="../../../../../assets/images/chat-img.svg" alt="">
                                </div> 
                            -->                                
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="text-description">
                    <textarea class="textarea" placeholder="Enter message" (keyup.enter)="sendMessage()" [(ngModel)]="message"></textarea>
                    <!-- <div id="editor" [ngxSummernote]="config" [(ngModel)]="message"></div> -->
                    <button class="primary-btn" (click)="sendMessage()">Send</button>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../../../environments/environment';
import videojs from 'video.js';

@Component({
  selector: 'app-mediaplayer',
  templateUrl: './mediaplayer.component.html',
  styleUrls: ['./mediaplayer.component.scss']
})

export class MediaPlayerComponent implements OnInit {

  streamLink: string = '';
  player: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any, 
    public dialogRef: MatDialogRef<MediaPlayerComponent>
  ) { }

  ngOnInit(): void {
    this.streamLink = this.data.url;
  }

  ngAfterViewInit() {
    this.player = videojs(document.getElementById('sxmvideo'));
    this.player.muted(false);
  
    this.player.src({
      src: this.streamLink
    
      });
      this.player.play();
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
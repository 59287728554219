
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Api } from '../../../../core/providers/api/api';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsService extends Api {
  // for get method
  public params: any = {};
  // url
  private basUrl = this.getBaseUrl();

  public getShipmentList(): Observable<any> { 
    return this.http.get(this.basUrl + '/shipments/list');
  }

  public generateLabel(params:any): Observable<any> {
    return this.http.post(this.basUrl + '/shipment/createPackageLabel', params);
  }

  public unBundleOrders(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/shipments/unbundling', params);
  }

  public bundleOrders(params: any): Observable<any> {
    return this.http.post(this.basUrl + '/shipments/bundling', params);
  }

  public updateShipment(params: any, shipmentId:number): Observable<any> {
    return this.http.post(this.basUrl + '/shipments/edit-shipment/' + shipmentId, params);
  }

  public printLabel(shipmentId: number): Observable<any> {
    return this.http.get(this.basUrl + '/shipments/print-label/' + shipmentId);
  }

  public getPreviousStream(streamtype) : Observable<any> {
    return this.http.get(this.basUrl + '/stream/vendor-stream?streamType=' +streamtype);
  }

  public getStreamShipmentList(id, limit, offSet) : Observable<any> {
    return this.http.get(this.basUrl + '/shipments/list', {
      params: {
        ...(id && {streamId: id}),
        ...(limit && {limit}),
        ...(offSet && {offset:offSet}),
      }
    });
  }

  /**
   * @method -- get list count
   * @param id 
   * @returns 
   */
  public getListCount(id) : Observable<any> {
    return this.http.get(this.basUrl + '/shipments/list', {
      params: {
        count: true,
        ...(id && {streamId: id}),

      }
    });
  }

  /**
   * @method -- get Amount Count
   * @returns 
   */
  public getAmountCount() : Observable<any> {
    return this.http.get(this.basUrl + '/shipments/total-amount');
  }
} 
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from '../../../../core/providers/api/api';

@Injectable()

export class HelpCenterApiService extends Api {

    private baseUrl: string = this.getBaseUrl();

    public AllCategories(): Observable<any> {
        return this.http.get(this.baseUrl + '/help/get_help_category');
    }

    public SendMessage(params: any): Observable<any> {
        return this.http.post(this.baseUrl + '/help/Create_message', params);
    }

    public GetAllConversations(params : any): Observable<any> {
        const queryParams = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return this.http.get(this.baseUrl + '/help/get_conversation?' + queryParams);
    }

    public GetAllConversationMessages(conversationId: number): Observable<any> {
        return this.http.get(this.baseUrl + '/help/get_conversation_messages?conversationId=' + conversationId);
    }
}
import { IMenuItem } from '../../interfaces/interface';

export const menu: IMenuItem[] = [
  {
    id: 1,
    path: '/dashboard',
    title: 'Dashboard',
    image: 'assets/images/aside-icon1.svg',
    activeImage: 'assets/images/aside-icon1-active.svg',
    submenu: []
  },
  {
    id: 2,
    path: '/our-products',
    title: 'Our Products',
    image: 'assets/images/our-product-icon.svg',
    activeImage: 'assets/images/our-product-icon-active.svg',
    submenu: [
      {
        id: 3,
        path: '/our-products/products',
        title: 'Products',
        image: 'assets/images/our-product-icon.svg',
        activeImage: 'assets/images/our-product-icon-active-submenu.svg',
      },
      {
        id: 4,
        path: '/our-products/menus',
        title: 'Product Menus',
        image: 'assets/images/aside-icon10.svg',
        activeImage: 'assets/images/aside-icon10-active-submenu.svg',
      }
    ]
  },
  {
    id: 5,
    path: '/stream/welcome',
    title: 'Live Streams',
    image: 'assets/images/aside-icon5.svg',
    activeImage: 'assets/images/aside-icon5-active.svg',
    submenu: []
  },
  {
    id: 6,
    path: '/stream/past-shows',
    title: 'Past shows',
    image: 'assets/images/aside-icon10.svg',
    activeImage: 'assets/images/aside-icon10-active.svg',
    submenu: []
  },
  {
    id: 7,
    path: '/orders',
    title: 'Orders',
    image: 'assets/images/aside-icon2.svg',
    activeImage: 'assets/images/aside-icon2-active.svg',
    submenu: []
  },
  {
    id: 8,
    path: '/shipments',
    title: 'Shipments',
    image: 'assets/images/aside-icon4.svg',
    activeImage: 'assets/images/aside-icon4-active.svg',
    submenu: []
  },
  {
    id: 9,
    path: '/payments/list',
    title: 'Payments',
    image: 'assets/images/aside-icon3.svg',
    activeImage: 'assets/images/aside-icon3-active.svg',
    submenu: []
  },
  {
    id: 10,
    path: '/payments/ledger',
    title: 'Ledger',
    image: 'assets/images/ledger-icon.svg',
    activeImage: 'assets/images/ledger-icon-active.svg',
    submenu: []
  },
  {
    id: 11,
    path: '/payout/payout',
    title: 'Payout',
    image: 'assets/images/payout-aside.svg',
    activeImage: 'assets/images/payout-aside-active.svg',
    submenu: []
  },
];
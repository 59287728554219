<div class="date-wrapper">
    <mat-form-field appearance="fill" class="example-form-field">
        <label class="label">Date Range</label>
        <mat-date-range-input [rangePicker]="rangePicker">
            <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
            <input matEndDate placeholder="End date" [(ngModel)]="endDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker>
            <mat-date-range-picker-actions>
                <button
                    mat-raised-button
                    color="primary"
                    matDateRangePickerApply
                    (click)="apply.emit({startDate, endDate})"
                >
                    Apply
                </button>
            </mat-date-range-picker-actions>
        </mat-date-range-picker>
    </mat-form-field>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchInputModule } from '../search-input/search-input.module';
import { DateRangeFilterModule } from '../date-range-filter/date-range-filter.module';
import { FilterComponent } from './filter.component';

@NgModule({
  imports: [
    CommonModule,
    SearchInputModule,
    DateRangeFilterModule,
    FormsModule
  ],
  declarations: [
    FilterComponent,
  ],
  exports: [
    FilterComponent
  ]
})

export class FilterModule { }
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddProductComponent } from './add-product.component';
import { MediaService } from '../../../../core/media/media.service';
import { StreamApiService } from '../../../../core/stream/stream.service';
import { CloudinaryModule } from '@cloudinary/ng';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CloudinaryModule
  ],
  declarations: [
    AddProductComponent,
  ],
  exports: [
    AddProductComponent
  ],
  providers: [
    MediaService,
    StreamApiService
  ]
})

export class AddProductModule { }
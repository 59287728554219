import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StreamApiService } from '../../../../../core/stream/stream.service';
import { StreamSandbox } from '../../../../../core/stream/stream.sandbox';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../../environments/environment';
import { Cloudinary } from "@cloudinary/url-gen";
import { crop } from "@cloudinary/url-gen/actions/resize";
import { SocialmediaComponent } from '../socialmedia/socialmedia.component';
import { MatDialog } from '@angular/material/dialog';
import { getLocale } from '../../../../../core/shared/utility/utilityHelpers';
import { IActionDropdownOption } from '../../../../shared/components/actions-dropdown/interfaces';


@Component({
  selector: 'app-my-live-stream',
  templateUrl: './my-live-stream.component.html',
  styleUrls: ['./my-live-stream.component.scss']
})

export class MyLiveStreamComponent implements OnInit {
  actions: IActionDropdownOption[] = [
    {
      icon: 'edit',
      title: 'Edit Stream',
      actionType:'edit'
    },
    {
      icon: 'open_in_new',
      title: 'Open Stream',
      actionType:'open_in_new'
    },
    {
      icon: 'share',
      title: 'Share',
      actionType:'share'
    },
    {
      icon: 'delete',
      title: 'Delete Stream',
      actionType:'delete'
    }
  ];
  userStreams: any[] = [];
  public imageUrl = environment.imageUrl;
  public cloudName = environment.cloudName;
  public cloudFolder = environment.cloudFolder;
  public vendorPrefixId = JSON.parse(localStorage.getItem('vendorUserDetails')).vendorPrefixId;
  public prefixId = this.vendorPrefixId + '/';
  currentFolder: string = '';
  streamUUID : string = '';
  cld: any;

  constructor(
    public streamSandbox: StreamSandbox, 
    private router: Router, 
    public service : StreamApiService, 
    private toastr: ToastrService,
    public modal: NgbModal,
    public dialog: MatDialog
  ) 
  {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
   }

  ngOnInit(): void {
    this.cld = new Cloudinary({
      cloud: {
        cloudName: this.cloudName,
      }
    });
    const asideBarElement = document.getElementsByClassName('aside-bar')[0] as HTMLElement;
    if(asideBarElement) { asideBarElement.parentElement.style.display = 'block'; }
    this.getMyStreams();
    this.currentFolder = this.vendorPrefixId;
  }

  prepareStreamData(stream: any) {
    const locale = getLocale();
    const format = new Intl.DateTimeFormat(locale, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

    stream.disableStart = (Number(stream.scheduletimeStamp) - new Date().getTime()) / 1000 > 600;
    stream.scheduleDate = format.format(new Date(Number(stream.scheduletimeStamp)));
    const previewVideoUrl = this.cloudFolder + "/"+ this.currentFolder + "/" + stream.preview_image;
    stream.preview_video = this.cld.video(previewVideoUrl.split('.')[0]);
    stream.preview_video.resize(crop().width(88).height(125));
    stream.preview_image = this.cld.image(previewVideoUrl + '.png');
    stream.preview_image.resize(crop().width(88).height(125));
    return stream;
  }

  getMyStreams() {
    const params = {
      user_id: localStorage.getItem('vendorUserDetails') ? JSON.parse(localStorage.getItem('vendorUserDetails')).id : 0,
      streamType: 1
    };
    this.service.getStreamList(params).subscribe((result : any) => {
      if (result) {
        this.userStreams = result.data;        
        this.userStreams.forEach((stream) => this.prepareStreamData(stream));
      } 
      else {
        this.userStreams = [];
      }
    });
  }

  /**
  * @method createStream -- create stream Modal
  */
  createStream(stream : any)  {
    // if (stream)
    //   this.router.navigate(['/stream/edit-stream', stream?.uuid]);
    // else
      this.router.navigate(['/stream/add-stream']);
  }

  startStreamClick(stream: any, from: string): void {
    localStorage.setItem('currentStreamDetails', JSON.stringify(stream));
    localStorage.setItem('clickedLink', from);
    this.router.navigate(['/stream/post-schedule-stream']);
  }

  deleteStream(stream: any) {
    this.service.deleteStream(stream.uuid).subscribe((result : any) => {
      if (result && result.status === 1) {
        this.toastr.success(result.data);
        this.getMyStreams();
      } else {
        this.toastr.error(result.data);
      }
    })
  }

  /**
   * @method -- share on social media
   */
  socialMedia(stream : any) {
    this.dialog.open(SocialmediaComponent, {
      width: '400px',
      height: '300px',
      data: {
        id : stream.id,
        uuid : stream.uuid
      }
    }).afterClosed().subscribe(() => {
      
    });
  }

  onAction({action, stream}: {action: IActionDropdownOption, stream: any}): void {
    switch(action.actionType) {
    case 'edit':
      this.router.navigate(['/stream/edit-stream', stream.uuid]);
      return;
    case 'open_in_new':
      this.startStreamClick(stream, 'open');
      return;
    case 'share':
      this.socialMedia(stream);
      return;
    case 'delete':
      this.deleteStream(stream);
      return;
    }
  }
}
<mat-card>
    <div class="update-shipment-wrapper">
        <div class="form-head-title flex-sb items-center flex">
            <h3 class="title-text" *ngIf="!data.product_id">Update Shipment</h3>
            <img class="close-btn" src="../../../../../../assets/images/cancel-icon.svg" alt="" (click)="closeDialog()"/>
        </div>
         <form [formGroup]="editShipmentFormGroup" (ngSubmit)="onSubmit()">             
             <div class="form-wrapper">                    
                 <div class="form-col">
                    <label class="lable-text" for="">Product Height (Inches)</label>
                    <input class="control-input" type="number" placeholder="Product Height (Inches)" onpaste="return false;" min="1" max="15" maxlength="2" (keypress)="numberOnlyHeight($event)" formControlName="height" required>
                    <span class="text-danger" *ngIf="editShipmentFormGroup.controls.height.touched">
                        <span *ngIf="editShipmentFormGroup.controls['height'].hasError('required')">
                            Height is required* 
                        </span>
                        <span class="" *ngIf="editShipmentFormGroup.controls['height'].value > 15">
                            Max height allowed is 15
                          </span>
                    </span>
                </div>
                <div class="form-col">
                     <label class="lable-text" for="">Product Width (Inches)</label>
                     <input class="control-input" type="number" placeholder="Product Width (Inches)" onpaste="return false;" min="1" max="18" maxlength="2" (keypress)="numberOnlyWidth($event)" formControlName="width" required>
                     <span class="text-danger" *ngIf="editShipmentFormGroup.controls.width.touched">
                        <span *ngIf="editShipmentFormGroup.controls['width'].hasError('required')">
                            Width is required* 
                        </span>
                        <span class="" *ngIf="editShipmentFormGroup.controls['width'].value > 18">
                            Max width allowed is 18
                          </span>
                    </span>
                </div>
                <div class="form-col">
                     <label class="lable-text" for="">Product Length (Inches)</label>
                     <input class="control-input" type="number" placeholder="Product Length (Inches)" onpaste="return false;" min="1" max="22" maxlength="2" (keypress)="numberOnlyLength($event)" formControlName="length" required>
                     <span class="text-danger" *ngIf="editShipmentFormGroup.controls.length.touched">
                        <span *ngIf="editShipmentFormGroup.controls['length'].hasError('required')">
                            Length is required*
                        </span>
                        <span class="" *ngIf="editShipmentFormGroup.controls['length'].value > 22">
                            Max length allowed is 22
                          </span>
                    </span>
                </div>
                <div class="form-col">
                    <label class="lable-text" for="">Product Weight (Ounces)</label>
                    <input class="control-input" type="number" placeholder="Product Weight (Ounces)" onpaste="return false;" min="1" max="999" (keypress)="numberOnlyWeight($event)" formControlName="weight" required>
                    <span class="text-danger" *ngIf="editShipmentFormGroup.controls.weight.touched">
                       <span *ngIf="editShipmentFormGroup.controls['weight'].hasError('required')">
                        Weight is required* 
                       </span>
                       <span class="" *ngIf="editShipmentFormGroup.controls['weight'].value > 999">
                            Max weight allowed is 999
                        </span>
                   </span>
                </div>     
            </div>                       
            <div class="action-btn ">
                <button class="primary-btn" type="submit" [disabled]="!editShipmentFormGroup.valid">Update Shipment</button>
            </div> 
        </form>
    </div>   
</mat-card> 
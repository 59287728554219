import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, EventEmitter, forwardRef,
  Input, Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true
    }
  ]
})
export class SearchInputComponent implements ControlValueAccessor {

  searchQuery: string;

  @Input() placeholder = 'Search by name';

  @Output() apply = new EventEmitter();

  private _propagateChange = (query: string) => {};
  private _propagateTouched = () => {};

  constructor(
    private cdRef: ChangeDetectorRef
  ) { }

  registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._propagateTouched = fn;
  }

  setDisabledState(): void {
  }

  writeValue(query: string): void {
    this.searchQuery = query;
    this.cdRef.markForCheck();
  }

  onChange(event): void {
    const target = event.target as HTMLInputElement;
    this.propagateChange(target.value);
  }

  propagateChange(query: string): void {
    this.searchQuery = query;
    this._propagateChange(this.searchQuery);
    this.cdRef.markForCheck();
  }

  onBlur(): void {
    this._propagateTouched();
  }

  emit(): void {
    this.apply.emit();
  }
}

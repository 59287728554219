<div class="maintanence-outline">

    <div class="maintain-containor">
        <div class="maintain-imgcontainer">
            <img src="../../assets/images/maintenance-img.png" alt="" />
            <h3>Application under Maintenance, Sorry for the inconvenience</h3>
            <img src="../../assets/images/back-soon.jpg" alt=""/>
        </div>

    </div>

</div>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


// components
import { PaymentsComponent } from './layout/payments.component';
import { PaymentComponent } from './components/payments/payments.component';
import { EarningsComponent } from './components/earnings/earnings.component';
import { SharedModule } from '../../../shared/shared.module';
import { PaymentSandbox } from '../../../../core/payment/payment.sandbox';
import { PaymentService } from '../../../../core/payment/payment.service';
import { PaymentEffect } from '../../../../core/payment/payment-effects/payment.effects';
import { EffectsModule } from '@ngrx/effects';
import { ArchivePaymentsComponent } from './components/archive-payments/archive-payments.component';
import { LedgerComponent } from './components/ledger/ledger.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export const routes = [
    {
        path: 'list',
        component: PaymentComponent,
        children: [
            // {
            //     path: 'list',
            //     component: PaymentComponent,
            //     data: {
            //         title: 'Payments',
            //         urls: [{ title: 'Home', url: '/dashboard'}, {title: 'Payments', url: '/payments/list'}, {title: 'Payments'}]
            //       }
            // },
            // {
            //     path: 'earnings',
            //     component: EarningsComponent, 
            //     data: {
            //         title: 'Payments',
            //         urls: [{ title: 'Home', url: '/dashboard'}, {title: 'Payments', url: '/payments/list'}, {title: 'Earnings'}]
            //       }
            // },
            // {
            //     path: 'archive',
            //     component: ArchivePaymentsComponent,
            //     data: {
            //         title: 'Payments',
            //         urls: [{ title: 'Home', url: '/dashboard'}, {title: 'Payments', url: '/payments/list'}, {title: 'Archive Payments'}]
            //       }
            // },
            // {
            //     path: '',
            //     redirectTo: 'list',
            //     pathMatch: 'full'
            // },
        ]
    },
    {
        path: 'earnings',
        component: EarningsComponent,
        data: {
            title: 'Payments',
            // urls: [{ title: 'Home', url: '/dashboard' }, { title: 'Payments', url: '/payments/list' }, { title: 'Earnings' }]
        }
    },
    {
        path: 'ledger',
        component: LedgerComponent,
        data: {
            title: 'ledger',
            // urls: [{ title: 'Home', url: '/dashboard' }, { title: 'Payments', url: '/payments/list' }, { title: 'Archive Payments' }]
        }
    },
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        SharedModule,
        FormsModule,
        EffectsModule.forFeature([PaymentEffect]),
        NgbModule,
        NgSelectModule
    ],
    declarations: [
        PaymentsComponent,
        PaymentComponent,
        EarningsComponent,
        ArchivePaymentsComponent,
        LedgerComponent
    ],
    providers: [
        PaymentSandbox, 
        PaymentService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' }
    ]
})

export class PaymentsModule { }
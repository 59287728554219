import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent {

  rangeFilter: {
    startDate: string,
    endDate: string
  };
  searchQuery = '';

  @Output() action = new EventEmitter<Params>();

  emitAction(): void {
    this.action.emit({
      ...(this.searchQuery && {keyword: this.searchQuery}),
      ...(this.rangeFilter || {})
    });
  }

  applyDateRangeFilter({startDate, endDate}: {startDate: Date, endDate: Date}): void {
    this.rangeFilter = {
      startDate: startDate ? startDate.getFullYear() + '-' + (startDate.getMonth()+ 1) + '-' + startDate.getDate() : '',
      endDate: endDate ? endDate.getFullYear() + '-' + (endDate.getMonth()+ 1) + '-' + endDate.getDate() : ''
    };
    this.emitAction();
  }

  clearFilter(): void {
    this.searchQuery = '';
    this.rangeFilter = null;
    this.emitAction();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public noInternetConnection = false;
  private internetConnectionSubscription = Subscription.EMPTY;

  constructor(
    private connectionService: ConnectionService
  ) {
  }

  ngOnInit(): void {
    this.internetConnectionSubscription = this.connectionService.monitor().subscribe(isConnected => {
      this.noInternetConnection = !isConnected;
    })
  }

  ngOnDestroy(): void {
    this.internetConnectionSubscription.unsubscribe();
  }
}

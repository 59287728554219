import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ShipmentsService } from '../shipments.service';

@Component({
  selector: 'app-edit-shipment',
  templateUrl: './edit-shipment.component.html',
  styleUrls: ['./edit-shipment.component.scss']
})

export class EditShipmentComponent implements OnInit {

  editShipmentFormGroup: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<EditShipmentComponent>, 
    private toastr: ToastrService, 
    public service: ShipmentsService) 
  { }

  ngOnInit(): void {
    this.editShipmentFormGroup = new FormGroup({
      weight: new FormControl(this.data.shipmentDetails.sm_weight.replace('.00',''), [Validators.required]),
      height: new FormControl(this.data.shipmentDetails.sm_dimensions.split('x')[0].replace('.00',''), [Validators.required]),
      width: new FormControl(this.data.shipmentDetails.sm_dimensions.split('x')[1].replace('.00',''), [Validators.required]),
      length: new FormControl(this.data.shipmentDetails.sm_dimensions.split('x')[2].replace('.00',''), [Validators.required])
    });
  }

  /**
   * @method -- close modal
   */
  closeDialog(){
    this.dialogRef.close();
  }

  /**
   * @method -- accept number Only for Height
   * @param event 
   * @returns 
   */
  numberOnlyHeight(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if(this.editShipmentFormGroup.value.height > 15 || event.currentTarget.value.length > 1) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  /**
   * @method -- accept number Only for Width
   * @param event 
   * @returns 
   */
  numberOnlyWidth(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if(event.currentTarget.value > 18 || event.currentTarget.value.length > 1) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  /**
   * @method -- accept number Only for Length
   * @param event 
   * @returns 
   */
  numberOnlyLength(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if(event.currentTarget.value > 22 || event.currentTarget.value.length > 1) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  /**
   * @method -- accept number Only for Weight
   * @param event 
   * @returns 
   */
   numberOnlyWeight(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if(event.currentTarget.value.length > 2) {
      return false;
    }
    return true;
  }

  /**
   * @method -- Submit all data
   */
  onSubmit() {
    Object.keys(this.editShipmentFormGroup.controls).forEach((field) => {
      const control = this.editShipmentFormGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if (this.editShipmentFormGroup.status === "VALID") {
      let params: any = {
        "dimensions":this.editShipmentFormGroup.value.height + 'x' + this.editShipmentFormGroup.value.width + 'x' + this.editShipmentFormGroup.value.length,
        "height":this.editShipmentFormGroup.value.height,
        "width":this.editShipmentFormGroup.value.width,
        "length":this.editShipmentFormGroup.value.length,
        "weight": this.editShipmentFormGroup.value.weight
      };

      this.service.updateShipment(params, this.data.shipmentDetails.sm_id).subscribe(data => { 
        if (data && data.status === 1) {
          this.toastr.success(data.message);
          this.dialogRef.close(1);
        } 
        else {
          this.toastr.error(data.message);
        }
      });
    }
  }
}
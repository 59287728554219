import { Component, OnInit } from '@angular/core';
import { ShipmentsService } from '../shipments.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { EditShipmentComponent } from '../edit-shipment/edit-shipment.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})

export class ShipmentListComponent implements OnInit {

  previousStreams: any;
  streamId : any;
  public currentPage = 1;
  public offset = 0;
  public limit = 10;
  shipmentList:any[] = [];
  earnedAmount : number = 0;
  totalOrder : number = 0;
  unBundleObject = {
    shipmentId: 0,
    orderIds: [],
    customerId: 0
  };
  public labelUrl: string = environment.labelUrl;
  isCollapse  = [];
  countOrder : number = 0;
  isMobile : boolean = true;

  constructor(
    public service: ShipmentsService, 
    public toastr: ToastrService, 
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService
  ) { 
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  ngOnInit(): void {
    this.getPreviousStreams();
    this.getCountAmount();
    if(this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.isMobile = false;
    }
  }

  /**
   * @method -- get Previous Stream
   */
  getPreviousStreams() {
    this.service.getPreviousStream(2).subscribe((result: any) => {
      if (result) {
        this.previousStreams = result.data;
        // this.streamId = this.previousStreams[0].id;
        // if(this.streamId) {
        //   this.getShipmentData(this.streamId, this.limit, this.offset);
        //   this.getStreamListCount(this.streamId);
        // }
        this.getShipmentData('', this.limit, this.offset);
        this.getStreamListCount('');
        this.getMoreStreams();
      } 
      else {
        this.previousStreams = [];
        this.getMoreStreams();
      }
    });
  }

  /**
   * @method -- get Live Stream
   */
  getMoreStreams() {
    this.service.getPreviousStream(1).subscribe((result) => {
      if(result)  {
        result.data.forEach((element) => {
          this.previousStreams.push(element);
        })        
        this.previousStreams.forEach((stream) => {
          if(stream) {
            let options: Intl.DateTimeFormatOptions = {
              year: 'numeric', month: 'numeric', day: 'numeric',
              hour: 'numeric', minute: 'numeric', 
              hour12: true,
              timeZone: 'America/Los_Angeles'
            };
            const dateStr = stream.scheduleDate + 'T' + stream.scheduletime + 'Z';
            const dateSent = new Date(dateStr);
            stream.scheduleDate = new Intl.DateTimeFormat('en-US', options).format(dateSent);          
          }
        });
      }
      else {
        this.previousStreams = [];
      }
    })
  }

  /**
   * @method -- get Shipment Data
   * @param streamId 
   */
  getShipmentData(streamId, limit, offset) {
    this.service.getStreamShipmentList(streamId, limit, offset).subscribe(result => {
      if(result) {
        if(result && result.data) {
          this.shipmentList = result.data;
          this.shipmentList.forEach(ship => {
            let tax = 0;
            ship.shipmentProducts.forEach(element => {
              if(element.tax)
                tax += Number(element.tax);
            });
            ship["sm_tax"] = tax;
          })
        }
      }
      else {
        this.shipmentList = [];
      }
    });
  }

  /**
  * @mehod -- get stream list count
  * @param streamId 
  */
  getStreamListCount(streamId) {
    this.service.getListCount(streamId).subscribe(result => {
      if(result) {
        if(result && result.data) {
          this.countOrder = result.data;
        }
      }
      else {
        this.shipmentList = [];
      }
    });
  }

  /**
   * @method -- get count Amount
   */
  getCountAmount() {
    this.service.getAmountCount().subscribe(result => {
      if(result) {
        if(result && result.data) {
          this.earnedAmount = result.data.total_amount_earned;
          this.totalOrder = result.data.total_order_count;
        }
      }
      else {
        this.earnedAmount = 0;
        this.totalOrder = 0;
      }
    });
  }

  /**
  * @method -- Change Event
  * @param event 
  */
  selectChangeHandler(event: any) {
    this.streamId = event.target.value;
    this.shipmentList = [];
    this.getStreamListCount(this.streamId);
    this.getShipmentData(this.streamId, this.limit, this.offset);
  }

  /**
  * @method -- get collapse data
  * @param streamId 
  * @param limit 
  * @param offset 
  */
  getCollapseData(streamId, limit, offset) {
    this.isCollapse  = [];
    this.getShipmentData(streamId, limit, offset);
    this.getStreamListCount(this.streamId);
  }

  /**
  * @method -- un bundle Order
  */
  unbundleOrders() {
    let obj = this.unBundleObject;
    let params = {
      shipmentId: obj.shipmentId,
      customerId: obj.customerId,
      orderIds: obj.orderIds.map(order => {
        if (order.shipment === obj.shipmentId) {
          return order.order;
        }
      })
    };
    this.service.unBundleOrders(params).subscribe(result => {
      if(result && result.status === 1) {
        this.toastr.success(result.message);
        this.shipmentList = [];
        this.unBundleObject.shipmentId = 0;
        this.unBundleObject.customerId = 0;
        this.unBundleObject.orderIds = [];
        this.getShipmentData(this.streamId, this.limit, this.offset);
        this.getStreamListCount(this.streamId);
      }
      else {
        this.toastr.error(result.message);
      }
    });
  }

  /**
  * @method -- bundle Order 
  */
  bundleOrders() {
    let canbeBundled = true;
    let obj = this.unBundleObject;
    let params = {
      shipmentId: obj.shipmentId,
      customerId: obj.customerId,
      orderIds: obj.orderIds.map(order => {
        if (order.shipment !== obj.shipmentId) {
          return order.order;
        }
      })
    };
    if(canbeBundled) {
      this.service.bundleOrders(params).subscribe(result => {
        if(result && result.status === 1) {
          this.toastr.success(result.message);
          this.shipmentList = [];
          this.unBundleObject.shipmentId = 0;
          this.unBundleObject.customerId = 0;
          this.unBundleObject.orderIds = [];
          this.shipmentList = [];
          this.getShipmentData(this.streamId, this.limit, this.offset);
          this.getStreamListCount(this.streamId);
        }
        else {
          this.toastr.error(result.message);
        }
      });
    }
  }

  /**
  * @method -- check uncheck Shipment
  * @param event 
  * @param shipmentId 
  * @param customerId 
  */
  checkUncheckShipment(event : any, shipmentId: number, customerId: number) {
    if (event.currentTarget.checked) {
      if (this.unBundleObject.shipmentId > 0) {
        event.preventDefault();
      } 
      else {
        this.unBundleObject.shipmentId = shipmentId;
        this.unBundleObject.customerId = customerId;
      }
    } 
    else {
      this.isCollapse  = [];
      this.unBundleObject.shipmentId = 0;
      this.unBundleObject.customerId = 0;
      this.unBundleObject.orderIds = [];
      this.shipmentList = [];
      this.getShipmentData(this.streamId, this.limit, this.offset);
      this.getStreamListCount(this.streamId);
    }
  }

  /**
  * @method -- print label
  * @param shipmentId 
  */
  printLabel(shipmentId) {
    this.service.printLabel(shipmentId).subscribe(data => {
      if(data && data.status === 1) {
        window.open(this.labelUrl + data?.data?.label?.uploadPath + data?.data?.label?.fileName, '_blank');
      }
    });
  }

  /**
  * @method -- generate Label
  * @param shipmentId 
  * @param customerId 
  */
  generateLabel(shipmentId: number, customerId: number) {
    const date = new Date();
    let params = {
      "vendorId": JSON.parse(localStorage.getItem('vendorUser')).vendorId,
      "shipDate": date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
      "customerId":customerId,
      "shipmentId":shipmentId
    }
    this.service.generateLabel(params).subscribe(result => {
      if(result && result.status === 1) {
        this.shipmentList = []
        this.getShipmentData(this.streamId, this.limit, this.offset);
        this.getStreamListCount(this.streamId);
        this.toastr.success(result.message);
        console.log(result);
      } 
      else {
        this.toastr.error(result.message);
      }
    });
  }

  /**
  * @method -- edit Shipment
  * @param shipmentDetails 
  */
  editShipment(shipmentDetails) {
    this.dialog.open(EditShipmentComponent, {
      width: '300px',
      height: '260px',
      data: {
        shipmentDetails
      }
    }).afterClosed().subscribe((data) => {
      if(data && data === 1) {
        this.shipmentList = []
        this.getShipmentData(this.streamId, this.limit, this.offset);
        this.getStreamListCount(this.streamId);
      }
    });
  }

  /**
  * @method -- check uncheckOrders
  * @param event 
  * @param shipmentId 
  * @param orderId 
  */
  checkUncheckOrders(event : any, shipmentId: number, orderId: number) {
    if (event.currentTarget.checked) { 
      this.unBundleObject.orderIds.push({ order: orderId, shipment: shipmentId });
    } 
    else {
      const index = this.unBundleObject.orderIds.map(order => order.order).indexOf(orderId);
      if (index > -1) { 
        this.unBundleObject.orderIds.splice(index, 1); 
      }
    }
  }

  /**
  * @method pageChange -- page Change
  * @param event 
  */
  pageChange(event) {
   this.currentPage = event;
    this.offset = this.limit * (event - 1);
    this.shipmentList = []
    this.getShipmentData(this.streamId, this.limit, this.offset);
    this.getStreamListCount(this.streamId);
  }


  /**
  * @method -- active collapse
  * @param sm_uuid
  */  
  active(sm_uuid) {
    if(!sm_uuid) {
      this.isCollapse.push(1);
    }
    else {
      this.isCollapse.pop();
    }
  }

  /**
   * @method -- Active for mobile
   */
  activeInMobile() {
    this.isMobile = true;
  }

  /**
   * @method -- In Active for mobile
   */
  inActiveInMobile() {
    this.isMobile = false;
  }

  enableBundleBtn() {
    if (this.unBundleObject.shipmentId > 0) {
      return !this.unBundleObject.orderIds.map(order => order.shipment).includes(this.unBundleObject.shipmentId);
    } else {
      return false;
    }
  }

  enableUnBundleBtn() {
    if (this.unBundleObject.shipmentId > 0) {
      return this.unBundleObject.orderIds.map(order => order.shipment).includes(this.unBundleObject.shipmentId);
    } else {
      return false;
    }
  }
}
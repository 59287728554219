export class AddProductModel {
    public user_id: Number;
    public title: string;
    public description: string;
    public moderator: string;
    public scheduleDate: string;
    public scheduletime: string;
    public category_id: Number;
    public subCategory_id: Number;
    public isExplicit: boolean;
    public isMute: boolean;

    constructor(payload: any) {
        this.user_id = payload.user_id;
        this.title = payload.title;
        this.description = payload.description;
        this.moderator = payload.moderator;
        this.scheduleDate = payload.scheduleDate;
        this.scheduletime = payload.scheduletime;
        this.category_id = payload.category_id;
        this.subCategory_id = payload.subCategory_id;
        this.isExplicit = payload.isExplicit;
        this.isMute = payload.isMute;
    }
}
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
import { HelpCenterApiService } from '../help-center.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-raise-request-details',
  templateUrl: './raise-request-details.component.html',
  styleUrls: ['./raise-request-details.component.scss']
})

export class RaiseRequestDetailsComponent implements OnInit, OnDestroy {

  raiseId : any;
  raiseDetails: any;
  public cloudFolder = environment.cloudFolder;
  currentFolder: any;
  vendorPrefixId = JSON.parse(localStorage.getItem('vendorUserDetails')).vendorPrefixId;
  cld: any;
  public cloudName = environment.cloudName;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  config: any;
  message : any;

  routerParamsSubscription = Subscription.EMPTY;

  constructor(
    private service: HelpCenterApiService,
    private router: Router, 
    private toastr: ToastrService, 
    private route: ActivatedRoute,
    private changeDetectRef: ChangeDetectorRef
  ) { 
    this.routerParamsSubscription = this.route.params.subscribe(params => {
      this.raiseId = params['id'];
    });
    window.scrollTo(0, document.body.scrollHeight);
  }

  ngOnInit(): void {
    this.currentFolder = this.vendorPrefixId;
    this.cld = new Cloudinary({
      cloud: {
        cloudName: this.cloudName,
      }
    });
    this.getDetails(this.raiseId);
    this.config = {
      placeholder: 'Enter message',
      height: '200px',
      toolbar: [
        ['font', ['bold', 'italic', 'underline']],
        ['para', ['style0', 'ul', 'ol']],
        ['insert', ['picture']],
      ],
    };
  }

  ngOnDestroy(): void {
    this.routerParamsSubscription.unsubscribe();
  }

  /**
   * @method -- get details
   * @param raiseId 
   */
  getDetails(raiseId) {
    this.service.GetAllConversationMessages(raiseId).subscribe((result : any) => {
      if (result && result.status === 1) {
        this.raiseDetails = result.data;
        this.raiseDetails?.messages?.forEach(element => {
          element.createdDate = (new Date(element.createdDate)).toDateString() + ' ' + (new Date(element.createdDate)).toTimeString().split(' ')[0];
          if(element.avatar && element.avatar_path) {
            element.urlImage = this.cld.image(this.cloudFolder + "/"+ element.avatar_path + "/" + element.avatar);
            element.urlImage.resize(fill().width(100).height(100)).border(byRadius(5));
          }
        });
        this.raiseDetails?.messages?.forEach(element => {
          element.createdDate = (new Date(element.createdDate)).toDateString() + ' ' + (new Date(element.createdDate)).toTimeString().split(' ')[0];
          if(element.admin_avatar && element.admin_avatar_path) {
            element.adminUrlImage = this.cld.image(this.cloudFolder + "/"+ element.admin_avatar_path + element.admin_avatar);
            element.adminUrlImage.resize(fill().width(100).height(100)).border(byRadius(5));
          }
        });
        this.changeDetectRef.detectChanges();
        window.scrollTo(0, document.body.scrollHeight);
        setTimeout(() => {
          this.scrollToBottom();
        });
      }
    });
  }

  /**
   * @method -- scroll to bottom
   */
  scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
  }

  /**
   * @method -- go to help center details
   */
  goToHelpCenter() {
    this.router.navigate(['/help-center']);
  }

  sendMessage() {
    if(!this.message) {
      this.toastr.error('Please enter message');
    }
    if(this.message) {
      let params = {
        "category" : this.raiseDetails?.subject,
        "sender" : JSON.parse(localStorage.getItem('vendorUserDetails')).email,
        "senderId" : JSON.parse(localStorage.getItem('vendorUserDetails')).vendorId, 
        "recieverId" : this.raiseDetails?.senderId, 
        "reciever" : this.raiseDetails?.userName,
        "message" : this.message,
        "senderType" : "seller",
        "conversationId" : this.raiseId,
        "categoryId" : this.raiseDetails?.messages[0].h_category_id,
      };
      this.service.SendMessage(params).subscribe((data) => {
        if (data && data.status === 1) {
          this.message = '';
          window.scrollTo(0, 0);
          this.getDetails(this.raiseId);
        }
      });
    }    
  }
}
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {COMMA, ENTER, I} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import { ToastrService } from 'ngx-toastr';
import { MediaService } from '../../../../../core/media/media.service';
import axios from "axios";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StreamSandbox } from '../../../../../core/stream/stream.sandbox';
import { StreamApiService } from '../../../../../core/stream/stream.service';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { environment } from '../../../../../../environments/environment';
import { Cloudinary } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { DeviceDetectorService } from 'ngx-device-detector';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {filter, forkJoin, map, Observable, of, Subscription, switchMap, take} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductMenusService } from '../../our-products/product-menus/services/product-menus.service';
import { IProductMenu } from '../../our-products/product-menus/interfaces';
import { ProductsService } from '../../../../../default/shared/services/products.service'
import { IProduct, ProductSellType } from '../../../../../default/shared/interfaces/interface';

@Component({
  selector: 'app-add-stream',
  templateUrl: './add-stream.component.html',
  styleUrls: ['./add-stream.component.scss']
})
export class AddStreamComponent implements OnInit, OnDestroy {

  streamID: any;
  productMenu: IProductMenu;

  imageData : any;
  imageName : string = '';
  currentFolder: string = '';
  isAddStream : boolean = false;
  public vendorPrefixId = JSON.parse(localStorage.getItem('vendorUserDetails')).vendorPrefixId;
  scheduleFormGroup: FormGroup;
  allCategories: any[] = [];
  parentCategories: any[] = [];
  secondaryCategories: any[] = [];
  isSecondary : boolean = false;
  minDate: Date = new Date();
  explicitContent: boolean = false;
  isImage : boolean = false;
  imageRequired : string = '';
  streamUuid: any = null;
  public imageUrl = environment.imageUrl;
  public cloudName = environment.cloudName;
  public cloudFolder = environment.cloudFolder;
  urlImage: any;
  isClick : boolean = false;
  cld: any;
  private isMenuSelected = false;
  productsOptions: {item?: IProduct | IProductMenu, type: string, disabled: boolean}[] = [];


  get isListSelected(): boolean {
    return !!this.scheduleFormGroup.value.productsAndMenus?.find(item => item.type === 'product');
  }

  params: { 
    path: string; 
    fileName: any; 
  };
  randomString : string = '';
  imageWithRandomString : string = '';

  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl('');
  filteredFruits: any[];
  fruits: string[] = [];
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  moderator: any[] = [];
  videoUrlCld: any;

  streamSubscription = Subscription.EMPTY;
  categoriesSubscription = Subscription.EMPTY;
  addStreamSubscription = Subscription.EMPTY;
  
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.fruits.push(value.trim());
    }

    // Clear the input value
    event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
      this.moderator = this.moderator.filter(i => i.name != fruit);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.moderator.push({ id: event.option.value, name: event.option.viewValue});
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
    this.filteredFruits = [];
  }

  selectedValueChange(event){
    //if(this.fruits.length < 1) {
      this.streamService.searchUserNames(event.currentTarget.value).subscribe((result) => {
        this.filteredFruits = result.data;
      });
    // } else {
    //   event.stopPropagation();
    //   event.preventDefault();
    //   return false;
    // }
  }

  constructor(
    private toaster: ToastrService,
    public service : MediaService,
    public streamSandbox: StreamSandbox,
    public streamService: StreamApiService,
    public dateTimeAdapter: DateTimeAdapter<any>,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute,
    private productMenusService: ProductMenusService,
    private productsService: ProductsService
  ) {
    dateTimeAdapter.setLocale('en-US');
    this.streamID = this.route.snapshot.paramMap.get('id') || '';
  }

  ngOnInit(): void {
    this.currentFolder = this.vendorPrefixId;
    this.cld = new Cloudinary({
      cloud: {
        cloudName: this.cloudName,
      }
    });
    this.scheduleFormGroup = new FormGroup({
      title: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(100)]),
      category: new FormControl(null, [Validators.required]),
      secondaryCategory: new FormControl(null, [Validators.required]),
      date: new FormControl("", [Validators.required]),
      description: new FormControl("",[Validators.required, Validators.minLength(3), Validators.maxLength(500)]),
      productsAndMenus: new FormControl([]) 
    }); 
    this.getProductMenus();
    this.streamSandbox.getCategoryList();
    this.categoriesSubscription = this.streamSandbox.categoryListLoaded$.pipe(
      filter(Boolean),
      switchMap(() => this.streamSandbox.categoryList$),
      take(1)
    ).subscribe((data) => {
      if (data) {
        this.allCategories = data;
        this.parentCategories = data.filter(this.isParentCategory);

        if (this.streamID !='' && this.secondaryCategories.length == 0) {
          this.getStreamUUID(this.streamID);
        }

        var ele = document.getElementsByClassName('ng-select-container')[0];
        if(ele) {
          ele.setAttribute("style", "width: 100% !important;");
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.streamSubscription.unsubscribe();
    this.categoriesSubscription.unsubscribe();
    this.addStreamSubscription.unsubscribe();
  }

  getProductMenus(): void {
    forkJoin([
      this.productsService.getProductList({
        status: 1,
        sellType: `${ProductSellType.AUCTION},${ProductSellType.BUY_NOW},${ProductSellType.GIVEAWAY}`
      }),
      this.productMenusService.getMenus({})
    ]).subscribe(([products, menus]) => {
      this.productsOptions = [
        ...products.data.map(product => ({item: product, type: 'product', disabled: false})),
        ...menus.data.map(menu => ({item: menu, type: 'menu', disabled: false}))
      ];

      if (this.productMenu) {
        this.onMenuSelectionChange([{item: this.productMenu, type: 'menu', disabled: false}]);
      }
    });
  }

  onMenuSelectionChange(selected): void {
    const selectedMenu = selected.find(item => item.type === 'menu');
    const isMenuSelectionChanged = this.isMenuSelected ? !selectedMenu : selectedMenu;
    this.isMenuSelected = !!selectedMenu;

    if (isMenuSelectionChanged) {
      if (selectedMenu) {
        this.productsOptions.forEach(option => {
          option.disabled = option.type === 'menu' && (option.item as IProductMenu).productListId !== selectedMenu.item.productListId
        });
      } else {
        this.productsOptions.forEach(option => option.disabled = false);
      }
    }
  }

  /**
   * @method -- get Stream Basedon UUID
   * @param streamID 
   */
  getStreamUUID(streamID) {
    this.streamService.getStreamUUId(streamID).pipe(
      map(result => result.data),
      filter(Boolean),
      switchMap(data => this.productMenusService.getMenus({
        ids: data.listId
      }).pipe(
        map(listData => ({streamData: data, listData: listData.data}))
      ))
    ).subscribe(({streamData: data, listData}) => {
      const selectedMenu = listData?.[0];
      const selectedProducts = data?.products?.map(product => ({
        item: {...product, productId: Number(product.product_id)},
        type: 'product',
        disabled: false
      })) || [];

      this.productMenu = selectedMenu;
      const productsAndMenus = selectedMenu ? [{item: selectedMenu, type: 'menu', disabled: false}, ...selectedProducts] : [...selectedProducts];

      if (data) {
        this.secondaryCategories = this.allCategories.filter((c) => c.parentInt == data.category_id);
        this.isSecondary = true;
        this.isAddStream = true;
        this.explicitContent = data.isExplicit;
        this.isClick = data.isExplicit;

        if(data.moderatorData) {
          this.fruits = [];
          data.moderatorData.forEach(element => {
            this.moderator.push({ id: element.id, name: element.username });
            this.fruits.push(element.username);
          });
        } else {
          this.fruits = [];
        }
        this.urlImage = this.cld.image(this.cloudFolder + "/"+ data.preview_image[0].containerName + "/" + data.preview_image[0].image);
        this.urlImage.resize(fill().width(88).height(125)).border(byRadius(8));

        this.videoUrlCld = this.cld.video(this.cloudFolder + "/"+ this.currentFolder + "/" + data.preview_image[0].image.split('.')[0]);
        this.videoUrlCld.resize(fill().width(88).height(135));
        //this.imageUrl + '?path=' +data.preview_image[0].containerName + '&name='+data.preview_image[0].image;        
        this.imageName = data.preview_image[0].image;
        this.currentFolder = data.preview_image[0].containerName;
        this.scheduleFormGroup.patchValue({
          title: data.title, 
          category: data.category_id,
          secondaryCategory : data.subCategory_id,
          date: new Date(data.scheduleDate + 'T' + data.scheduletime + 'Z'),
          description: data.description,
          productsAndMenus
        });

        if (listData?.length && this.productsOptions?.length) {
          this.onMenuSelectionChange(productsAndMenus)
        }
      }
    });
  }

  isParentCategory(value) {
    return value.parentInt === 0;
  }

  /**
  * @method uploadImage - To Upload Image
  * @param event 
  */
  uploadImage(event) {
    if(event.target.files[0].size > 104857600) {
      // 2mb -- 2097152 
      this.toaster.error('Please upload a video less than 100MB');
    }
    else {
      if(event.target.files[0]) {
        let imageData = event.target.files[0].name.toLowerCase();
        if(imageData.endsWith('mp4') || imageData.endsWith('wmv')) {
          if(this.deviceService.os === 'iOS' && this.deviceService.device === 'iPhone' && this.deviceService.isMobile) {
            this.randomString = this.randomStringData(8);
            if(imageData === 'image.mp4' || imageData === 'image.wmv') {
              let imageWithRandomString = this.randomString + imageData;
              this.params = {
                path : this.currentFolder,
                fileName : imageWithRandomString
              }
              imageData = imageWithRandomString;
            }
            else {
              this.params = {
                path : this.currentFolder,
                fileName : imageData
              }
            }  
          }
          else {
            this.params = {
              path : this.currentFolder,
              fileName : imageData
            }
          }
          this.params['type'] = event.target.files[0].type;      
          this.service.imageUpload(this.params).subscribe((result) => {
            if(result && result.status === 1 && result.url) {
              const options = {
                headers: {
                  'Content-Type': this.params['type'],
                  'X-Goog-Acl': 'public-read'
                }
              };
              axios.put<any>(result.url, event.target.files[0], options).then((response) => {
                if(response.status === 200) {
                  this.service.imagePathUpload(this.params).subscribe((result) => {
                    this.toaster.success('Success', 'File uploaded successfully');
                    this.convertBase64(event.target.files[0]);
                    this.isAddStream = true;
                    this.isImage = false;
                    this.imageName = imageData
                    this.videoUrlCld = this.cld.video(this.cloudFolder + "/"+ this.currentFolder + "/" + imageData.split('.')[0]);
                    this.videoUrlCld.resize(fill().width(88).height(135));
                  })
                }
              }).catch((error) => {
                this.toaster.error('Video Not Uploaded!. Try again');
              }); 
            }
          })
        }
        else {
          this.toaster.error('Video must be MP4, WMV');
        }
      }
    }
  }

  /**
   * @method -- random String
   * @param length 
   * @returns 
   */
   randomStringData(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  /**
  * @method convertBase64  -- convert to Base 64 format
  * @param image 
  */
  convertBase64(image) {
    const file: File = image;
    const myReader: FileReader = new FileReader();
    myReader.onloadend = e => {
      this.imageData = myReader.result;
    };
    myReader.readAsDataURL(file);
  }

  /**
  * @method onPrimaryCategoryChange -- on Primary Category Change
  * @param event 
  */
  onPrimaryCategoryChange(event) {
    const categoryId = event;
    this.secondaryCategories = this.allCategories.filter((c) => c.parentInt == categoryId);
    this.scheduleFormGroup.controls.secondaryCategory.setValue(null);
    this.isSecondary = true;
  }

  /**
   * @method trackItem -- track Item
   * @param index 
   * @param item 
   * @returns 
   */
  public trackItem(index: number, item: any) {
    return item.categoryId;
  }

  /**
  * @method numericAlphaOnly
  * @param event 
  * @returns 
  */
  numericAlphaOnly(e: any) : boolean  {
    var regex = new RegExp("^[a-zA-Z0-9_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
  }

  toggleChange(event: any): void {
    this.explicitContent = event.target.checked;
  }

  /**
  * @method onSubmit -- Submit all data
  */
  onSubmit() {
    
    Object.keys(this.scheduleFormGroup.controls).forEach((field) => {
      const control = this.scheduleFormGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    if(!this.isAddStream) {
      this.isImage = true;
      this.imageRequired = 'Video is required*';
    } else {
      if (this.scheduleFormGroup.status === "VALID") {
        const {
          title, category, secondaryCategory,
          date, description, productsAndMenus
        } = this.scheduleFormGroup.value;
        const listId = productsAndMenus.find(item => item.type === 'menu')?.item?.productListId;
        const products = productsAndMenus.reduce((acc, item) => {
          if (item.type === 'product') {
            acc.push(item.item)
          }
          return acc;
        }, []);

        const params = {
          title,
          category_id: category,
          subCategory_id: secondaryCategory,
          scheduleDate: date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear(),
          scheduletime: date.getHours() + ':' + date.getMinutes(),
          description,
          isExplicit: this.explicitContent,
          preview_image: this.imageName,
          path: this.currentFolder,
          isMute: false,
          moderator: this.moderator.map(i => i.id),
          scheduledDateTime: date.getTime(),
          isVideo: true,
          ...(listId && {listId})
        };

        let streamRequest: Observable<any>;

        if (this.streamID) {
          streamRequest = this.streamService.updateStream(params, this.streamID);
        } else {
          streamRequest = this.streamService.addStream({payload: params});
        }

        streamRequest.pipe(
          switchMap(data => {
            if (data?.status === 1) {

              if (products?.length) {
                return forkJoin(
                  products.map((product: IProduct) => (
                    this.streamService.updateProduct(
                    {
                      streamUuid: this.streamID || data.data.uuid
                    },
                    product.productId
                  )))
                ).pipe(
                  map(() => data)
                )
              } else {
                return of(data);
              }
              
            }

            return of(data);
          })
        ).subscribe(data => {
          if (data?.status === 1) {
            this.toaster.success(data.message);
            this.router.navigate(['/stream/my-live-stream']);
          } else if (data?.message) {
            this.toaster.error(data?.message);
          }
        });
      }
    }
  }

  compareFn(item1: any, item2: any): boolean {
    return item1 && item2 && item1.type === item2.type && item1.item && item2.item &&
      (
        (item1.item.productId && item2.item.productId && item1.item.productId === item2.item.productId) ||
        (item1.item.productListId && item2.item.productListId && item1.item.productListId === item2.item.productListId)
      );
  }
}
<app-breadcrumbs></app-breadcrumbs>
<div class="live-steams">
    <div class="head-title flex flex-sb mb27">
        <h2>My past Shows</h2>

        <div class="right-part flex flex-sb items-center">
            <app-filter (action)="applyFilter($event)">
            </app-filter>
        </div>
    </div>
   
    <div class="card-wrapper">
        <div class="card flex flex-sb items-center mb16" *ngFor="let stream of userStreams;">
            <div class="left-part flex items-center">
                <div *ngIf="stream.preview_image" class="imageData">
                    <advanced-image [cldImg]="stream.preview_image"></advanced-image>
                    <!-- <advanced-video [cldVid]="stream.preview_video" controls></advanced-video> -->
                </div>
                <div *ngIf="!stream.preview_image">
                    <img src="../../../../../../assets/images/livestreams.svg"/>
                </div>
                <div class="text-content">
                    <div class="title flex mb16">
                        <h3 title="{{stream.title}}">{{stream.title.substring(0,25)}}<span *ngIf="stream.title.length > 25">...</span></h3>
                        <button class="card-btn">{{stream.subCategory_name}}</button>
                    </div>
                    <div title="{{stream.description}}" class="description mb16">{{stream.description.substring(0,70)}}<span *ngIf="stream.description.length > 70">...</span></div>
                    <span class="time"> 
                        <img src="../../../../../../assets/images/time.svg" alt="live-steams" />
                        Happened on: {{stream.scheduleDate}}
                    </span>
                </div>
            </div>
            <div class="right-part column flex">
                <app-actions-dropdown
                    class="menu-icon"
                    [actions]="actions"
                    (action)='onAction({action: $event, stream})'
                >
                </app-actions-dropdown>
            </div>
        </div>       
    </div>
</div>

<div class="live-steams no-streams" *ngIf="userStreams != null && userStreams.length == 0">   
    <div class="card-wrapper">
        <img src="../../../../../assets/images/no-streams.svg" alt="no-streams" />
        <span class="worn">No past show available</span>
   </div>
</div>
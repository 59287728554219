import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../../../../../core/payment/payment.service';
import { PaymentSandbox } from '../../../../../../core/payment/payment.sandbox';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})

export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {

  public keyword = '';
  public filterForm: FormGroup;
  public filter = false;
  public miniDate: any;
  public dateError: string;
  public startDate: any;
  public isRequired = false;
  public endDate: any;
  public selectedAll: any;
  @ViewChild(NgbDropdown)
  public dropdown: NgbDropdown;
  public paymentArray = [];
  public filterData: any = [];
  public filterDataId = [];
  public limit = 10;
  public offset: any = 0;
  public currentPage: any = 1;
  public config: SwiperConfigInterface = {};
  public userDetails = JSON.parse(localStorage.getItem('vendor-settings'));
  public currencyCode = this.userDetails.currencyCode;
  private subscriptions: Array<Subscription> = [];
  public queryData: any = {};
  public count = 0;

  constructor(
    public paymentSandbox: PaymentSandbox,
    public paymentService: PaymentService,
    public formBuilder: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private toaster: ToastrService,
  ) { 
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  ngOnInit() {
    this.initFilterForm();
    this.offset = this.route.snapshot.queryParamMap.get('offset') || 0;
    this.currentPage = this.route.snapshot.queryParamMap.get('index');
    this.getPaymentList();
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 6,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        },
        1500: {
          slidesPerView: 5
        }
      }
    };
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required]
    });
  }

  getPaymentList() {
    const params = this.getPaymentsParams({excel: false, orderIds: ''});
    this.paymentService.paymentList(params).subscribe(data => {
      if (data) {
        this.paymentArray = [];
        this.paymentArray = data.data;
        this.paymentArray.forEach((payment) => {
          if(payment) {
            let options: Intl.DateTimeFormatOptions = {
              year: 'numeric', month: 'numeric', day: 'numeric',
              hour: 'numeric', minute: 'numeric', 
              hour12: true,
              timeZone: 'America/Los_Angeles'
            };
            const tempDate = new Date(payment.createdDate);
            payment.createdDate = new Intl.DateTimeFormat('en-US', options).format(tempDate);
          }
        });
        this.getPaymentCount();
      }
    });
  }

  searchPayment() {
    if(this.startDate && this.endDate) {
      this.offset = 0;
      this.limit = this.limit;
      this.keyword = this.keyword;
      this.startDate = this.startDate;
      this.endDate = this.endDate;
      this.getPaymentList();
    }
    else {
      this.offset = 0;
      this.limit = this.limit;
      this.keyword = this.keyword;
      this.startDate = '',
      this.endDate = '';
      this.getPaymentList();
    }
  }

  /**
  * @method -- for ios issue search
  * @param event 
  */
  onReturn(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 229 || charCode === 13 || event.key === 'Enter') {
      this.searchPayment();
    }
  }

  applyFilter() {
    if(!this.startDate && !this.endDate) {
      this.toaster.error('Please Provide Start Date and End Date');
    }
    else if(!this.startDate) {
      this.toaster.error('Choose Start Date First');
    }
    else if(!this.endDate) {
      this.toaster.error('Choose End Date');
    }
    else {
      this.offset = 0;
      this.limit = this.limit;
      this.keyword = this.keyword;
      this.startDate = this.startDate;
      this.endDate = this.endDate;
      this.getPaymentList();
    }
  }

  clearFilter() {
    this.keyword = '';
    this.startDate = null;
    this.endDate = null;
//    this.transactionFilterId = '';
    this.getPaymentList();
  }

  downloadInvoice(id) {
    const params: any = {};
    params.vendorOrderId = id;
    this.paymentSandbox.downloadInvoice(params);
  }

  goToOrders(id) {
    this.router.navigate(['/orders/order-details',id],
      { 
        queryParams: { 
          isOrder : 1
        } 
      }
    );
  }

  onDateSelect(event) {
    // event.stopPropogation();
    this.miniDate = event;
    this.dateError = '';
  }

  setMinValue(d) {
    this.isRequired = false;
    if (
      this.filterForm.controls['fromDate'].value === '' ||
      this.filterForm.controls['fromDate'].value === null
    )
    {
      this.dateError = 'Choose From Date First';
      return;
    }
    d.toggle();
  }

  close() {
    this.dropdown.close();
  }

  resetFilter() {
    this.startDate = '';
    this.endDate = '';
    this.filter = false;
    this.filterForm.reset();
    this.getPaymentList();
    this.getPaymentCount();
  }

  exportPayment() {
    if (this.filterDataId.length > 0) {
      const params = this.getPaymentsParams({
        excel: true,
        orderIds: "["+this.filterDataId.toString()+"]"
      });

      this.paymentService.ExportAllPayments(params).subscribe(data => {
        if (data) {
          try {
            const outputFilename = `${Date.now()}.xls`;
            const url = URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
          } catch (error) {
              throw Error(error);
          }
        }
      });
    }

  }

  selectAll() {
    for (let i = 0; i < this.paymentArray.length; i++) {
      this.paymentArray[i].selected = this.selectedAll;
    }
    this.filterDataList();
  }

  checkIfAllSelected() {
    this.selectedAll = this.paymentArray.every(function (item: any) {
      return item.selected === true;
    });
    this.filterDataList();
  }

  filterDataList() {
    this.filterData = this.paymentArray.filter(data => {
      if (data.selected === true) {
        return data;
      }
    });
    this.filterDataId = this.filterData.map(obj => obj.vendorOrderId);
  }

  pageChange(event) {
    this.currentPage = event;
    this.offset = this.limit * (event - 1);
    this.getPaymentList();
  }

  getPaymentCount() {
    this.subscriptions.forEach(each => each.unsubscribe());
    const params: any = {};
    params.offset = this.offset;
    params.limit = this.limit;
    params.keyword = this.keyword;
    params.startDate = this.startDate ? this.startDate : '';
    params.endDate = this.endDate ? this.endDate : '';
    this.paymentSandbox.getPaymentListCount(params);
    this.subscriptions.push(this.paymentSandbox.totalPaymentCount$.subscribe(data => {
      if (data) {
        this.count = Number(data?.salesCount);
      }
    }));
  }

  makeAchive(id) {
    const params: any = {};
    params.vendorPaymentId = id;
    this.paymentSandbox.makePaymentArchive(params);
    this.subscriptions.push(this.paymentSandbox.makePaymentArchive$.subscribe(data => {
      if (data && data['status'] === 1) {
        this.getPaymentList();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(each => each.unsubscribe());
  }

  /**
  * @method -- Change Event
  * @param event 
  */
  selectChangeHandler(event: any) {
//    this.transactionFilterId = event.target.value;
    if(this.startDate && this.endDate) {
      const params: any = {};
      params.offset = this.offset;
      params.limit = this.limit;
      params.keyword = this.keyword;
//      params.filter = this.transactionFilterId;
      params.startDate = this.startDate.getFullYear() + '-' + (this.startDate.getMonth()+ 1) + '-' + this.startDate.getDate();
      params.endDate = this.endDate.getFullYear() + '-' + (this.endDate.getMonth()+ 1) + '-' + this.endDate.getDate();
      this.paymentSandbox.getPaymentList(params);
      this.queryData.offset = this.offset || 0;
      this.queryData.index = this.currentPage || 1;
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: this.queryData,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    else {
      const params: any = {};
      params.offset = this.offset;
      params.limit = this.limit;
      params.keyword = this.keyword;
//      params.filter = this.transactionFilterId;
      params.startDate = '';
      params.endDate = '';
      this.paymentSandbox.getPaymentList(params);
      this.queryData.offset = this.offset || 0;
      this.queryData.index = this.currentPage || 1;
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: this.queryData,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
  }

  /**
   * @method -- get list of order list after clear search
   * @param event 
   */
  onKeydown(event) {
    if(this.keyword.length === 1) {
      if (event.key === "Backspace") {
        this.keyword = '';
        if(this.startDate && this.endDate) {
          const params: any = {};
          this.offset = 0;
          this.limit = this.limit;
          this.keyword = this.keyword;
          this.startDate = this.startDate;
          this.endDate = this.endDate;
          this.getPaymentList();
        }
        else {
          this.offset = 0;
          this.limit = this.limit;
          this.keyword = this.keyword;
          this.startDate = '';
          this.endDate = '';
          this.getPaymentList();
        }
      }
    }
  }
  
  exportAllPayments() {
    const params = this.getPaymentsParams({excel: true, orderIds: ''});

    this.paymentService.ExportAllPayments(params).subscribe(result => {
      try {
        const outputFilename = `${Date.now()}.xls`;
        const url = URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
          throw Error(error);
      }
    });
  }

  private getPaymentsParams({excel, orderIds}: {excel: boolean, orderIds: string}) {
    return {
      offset: this.offset,
      limit: this.limit,
      excel,
      orderIds,
      ...(this.keyword && {keyword: this.keyword}),
      ...(this.startDate && {startDate: this.startDate.getFullYear() + '-' + (this.startDate.getMonth()+ 1) + '-' + this.startDate.getDate()}),
      ...(this.endDate && {endDate: this.endDate.getFullYear() + '-' + (this.endDate.getMonth()+ 1) + '-' + this.endDate.getDate()})
    }
  }
}
<mat-card class="raise-request">
    <div class="form-head-title flex-sb items-center flex">
        <h3 class="title-text">Raise Request</h3> 
        <img class="close-btn" src="../../../../../../assets/images/cancel-icon.svg" alt=""  (click)="closeDialog()" />
    </div>

    <form [formGroup]="raiseRequestFormGroup" class="form-container">
        <div class="form-wrapper">
            <div class="form-col">
                <label>Subject*</label>
                <ng-select [searchable]="false" [clearable]="false" id="drpdwn" [items]="subjects" [(ngModel)]="subjectObj" formControlName="subject" bindLabel="categoryName" bindValue="Id" placeholder="Select Reason"></ng-select>
                <span style="color: red;" *ngIf="raiseRequestFormGroup.controls.subject.touched">
                    <span *ngIf="raiseRequestFormGroup.controls['subject'].hasError('required')">
                        Subject is required
                    </span>                
                </span>
            </div>
            <div class="form-col">
                <label>Additional Information*</label>
                <textarea type="text" class="description" placeholder="Enter message" (keyup)="limitChange($event)" maxlength="300" formControlName="message" cols="30" rows="7"></textarea>
                <span style="color: grey;float: right;">{{limit}} characters remaining</span>
                <span style="color: red;" *ngIf="raiseRequestFormGroup.controls.message.touched">
                    <span *ngIf="raiseRequestFormGroup.controls['message'].hasError('required')">
                        Information is required
                    </span>                
                </span>
            </div>
       </div>
        <div class="action-btn">
            <button class="border-btn" (click)="closeDialog()">Cancel</button>
            <button class="primary-btn" type="submit" (click)="onSubmit()">Submit</button>
       </div>
    </form>
</mat-card>

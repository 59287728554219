<div class="flex align-items-center">
    <app-search-input
        [(ngModel)]="searchQuery"
        (apply)="emitAction()"
    ></app-search-input>
    <app-date-range-filter
        (apply)="applyDateRangeFilter($event)"
    >
    </app-date-range-filter>
   <div class="action-wrap">
        <button
            *ngIf="searchQuery || rangeFilter"
            class="btn btn-default clear"
            (click)="clearFilter()"
        >
            Clear Filter
        </button>
   </div>
</div>

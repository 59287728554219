import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { Api } from '../../../core/providers/api/api';
import { IProduct } from '../interfaces/interface';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends Api {

  private basUrl = this.getBaseUrl();

  public getProductList(params: any): Observable<{status: number, data:IProduct[]}> {
    return this.http.get<{status: number, data:IProduct[]}>(this.basUrl + '/vendor-product/vendor-product-list', { params : params });
  }

  public updateStatus(params: any): Observable<any> {
    return this.http.put(this.basUrl + '/vendor-product/add-vendor-product-status/' + params.productId, params);
  }

  public exportProducts(params: any): Observable<any> {
    return this.http.get(this.basUrl + '/vendor-product/vendor-product-list', {
      params,
      headers: {'Content-Type': 'blob'},
      responseType: 'arraybuffer'
    });
  }

  public getProductDetail(productId: number): Observable<{status: number, data: IProduct}> {
    return this.http.get<{status: number, data: IProduct}>(this.basUrl + '/vendor-product/vendor-product-detail/' + productId);
  }

  public getProductsCount(params): Observable<{status: number, data: number}> {
    return this.http.get<{status: number, data: number}>(
      this.basUrl + '/vendor-product/vendor-product-list',
      { params: {...params, count: 1} }
    );
  }
}
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-addressmodal',
  templateUrl: './addressmodal.component.html',
  styleUrls: ['./addressmodal.component.scss']
})

export class AddressmodalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddressmodalComponent>,
  ) {}

  ngOnInit(): void { }

  /**
  * @method closeModal() - Its use for closeing Modal()
  */
   closeModal() {
    this.dialogRef.close();
     window.location.reload();
  }
}
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, EventEmitter, Output
} from '@angular/core';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeFilterComponent {

  startDate: Date;
  endDate: Date;

  @Output() apply = new EventEmitter<{startDate: string, endDate: string}>();

  constructor(
    private cdRef: ChangeDetectorRef
  ) { }

  public clearFilter(): void {
    this.startDate = null;
    this.endDate = null;
    this.cdRef.markForCheck();
  }
}

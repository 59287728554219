<div class="modal-header flex Space-between flex-center">
    <h5 class="modal-title">Share</h5>
</div>
<div class="social-media-div">
    <div class="icons">
        <button mat-fab shareButton="facebook" #fbBtn="shareButton" [style.backgroundColor]="fbBtn?.color" [url]="streamLink">
        <fa-icon *ngIf="fbBtn" [icon]="fbBtn.icon" size="lg"></fa-icon>
    </button>
    <button mat-fab shareButton="twitter" [url]="streamLink"
        #twtBtn="shareButton" [style.backgroundColor]="twtBtn?.color">
        <fa-icon *ngIf="twtBtn" [icon]="twtBtn.icon" size="lg"></fa-icon>
    </button>
    <button mat-fab shareButton="whatsapp" #wBtn="shareButton" [style.backgroundColor]="wBtn?.color" [url]="streamLink">
        <fa-icon *ngIf="wBtn" [icon]="wBtn.icon" size="lg"></fa-icon>
    </button>
    </div>
    <div class="copy flex space-between flex-center nowrap">
        <span class="url">{{streamLink}}</span>
        <share-buttons theme="modern-dark" [include]="['copy']" [showIcon]="true" [showText]="false" url="{{streamLink}}"></share-buttons>
    </div>
</div>
<section class="order-section">
    <div class="container order-lists">
        <div class="order-header flex flex-sb items-center">
            <h2>My Ledger</h2>
            <div class="Search-input">
                <input type="search" placeholder="Search by order" class="input-control" [(ngModel)]="keyword" (keydown.backspace)="onKeydown($event)" (keyup.enter)="searchLedger()" (keypress)="onReturn($event)">
                <button class="Search-input-btn" (click)="searchLedger()">
                     <svg width="15" height="15"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="icon" d="M14.5001 13.7931L10.7241 10.0171C11.6315 8.92772 12.0839 7.53048 11.9874 6.11601C11.8908 4.70154 11.2527 3.37875 10.2057 2.42282C9.15867 1.46688 7.78343 0.951395 6.36604 0.983601C4.94864 1.01581 3.59823 1.59322 2.59573 2.59573C1.59322 3.59823 1.01581 4.94864 0.983601 6.36604C0.951395 7.78343 1.46688 9.15867 2.42282 10.2057C3.37875 11.2527 4.70154 11.8908 6.11601 11.9874C7.53048 12.0839 8.92772 11.6315 10.0171 10.7241L13.7931 14.5001L14.5001 13.7931ZM2.00006 6.50006C2.00006 5.61004 2.26398 4.74001 2.75844 3.99999C3.25291 3.25997 3.95572 2.68319 4.77798 2.3426C5.60025 2.00201 6.50505 1.91289 7.37796 2.08652C8.25088 2.26016 9.0527 2.68874 9.68204 3.31808C10.3114 3.94741 10.74 4.74924 10.9136 5.62215C11.0872 6.49507 10.9981 7.39987 10.6575 8.22213C10.3169 9.0444 9.74015 9.74721 9.00012 10.2417C8.2601 10.7361 7.39007 11.0001 6.50006 11.0001C5.30699 10.9987 4.16317 10.5242 3.31954 9.68058C2.47591 8.83695 2.00138 7.69313 2.00006 6.50006Z" fill="#5C646C"></path>
                    </svg>
                </button>
            </div>
            <div class="date-wrapper">
                <mat-form-field appearance="fill" class="example-form-field">
                    <label class="label">Date Range</label>
                    <mat-date-range-input [rangePicker]="rangePicker">
                        <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
                        <input matEndDate placeholder="End date" [(ngModel)]="endDate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #rangePicker>
                        <mat-date-range-picker-actions>
                            <button mat-raised-button color="primary" matDateRangePickerApply (click)="applyFilter()">Apply</button>
                        </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
              </div>
            <button class="btn btn-default clear" (click)="ExportLedger()">
                Export
            </button>
            <button class="btn btn-default clear" (click)="clearFilter()" *ngIf="keyword || startDate || endDate">
                Clear Filter
            </button>
            <!-- <div class="right-part flex flex-sb items-center">
                <div class="Search">
                    <input type="text" placeholder="Search by order" (keyup.enter)="searchOrder($event.target.value)">
                    <button class="search-btn">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="icon" d="M14.5001 13.7931L10.7241 10.0171C11.6315 8.92772 12.0839 7.53048 11.9874 6.11601C11.8908 4.70154 11.2527 3.37875 10.2057 2.42282C9.15867 1.46688 7.78343 0.951395 6.36604 0.983601C4.94864 1.01581 3.59823 1.59322 2.59573 2.59573C1.59322 3.59823 1.01581 4.94864 0.983601 6.36604C0.951395 7.78343 1.46688 9.15867 2.42282 10.2057C3.37875 11.2527 4.70154 11.8908 6.11601 11.9874C7.53048 12.0839 8.92772 11.6315 10.0171 10.7241L13.7931 14.5001L14.5001 13.7931ZM2.00006 6.50006C2.00006 5.61004 2.26398 4.74001 2.75844 3.99999C3.25291 3.25997 3.95572 2.68319 4.77798 2.3426C5.60025 2.00201 6.50505 1.91289 7.37796 2.08652C8.25088 2.26016 9.0527 2.68874 9.68204 3.31808C10.3114 3.94741 10.74 4.74924 10.9136 5.62215C11.0872 6.49507 10.9981 7.39987 10.6575 8.22213C10.3169 9.0444 9.74015 9.74721 9.00012 10.2417C8.2601 10.7361 7.39007 11.0001 6.50006 11.0001C5.30699 10.9987 4.16317 10.5242 3.31954 9.68058C2.47591 8.83695 2.00138 7.69313 2.00006 6.50006Z" fill="#5C646C"></path>
                        </svg>
                    </button>
                </div> 
                <div ngbDropdown class="flex items-center">
                    <button class="date-range profile flex flex-ce align-items-center" id="dropdownBasic1" ngbDropdownToggle>
                        <span class="calendar-icon">
                            <img src="../../../../../../../assets/imgs/calendar.svg" alt="">
                        </span>
                        <ul class="dropDown">
                            <li class="active">{{fromDate}} 
                                <span *ngIf="fromDate && toDate">To</span>    
                                {{toDate ? toDate : ''}}
                            </li>
                            <li class="active" *ngIf="!fromDate">{{'Date Range'}} 
                                <span *ngIf="fromDate && toDate">To</span>    
                                {{toDate ? toDate : ''}}
                            </li>
                        </ul>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdownBasic1">
                        <div class=" d-flex flex-sb align-items-center head-title">
                            <div class="filter-action">
                                <h4 class="title">Filters</h4>
                            </div>
                            <div class="close-action">
                                <img src="../../../../../assets/imgs/close.svg" class="close" (click)="closeDropDown()">
                            </div>
                        </div>                   
                        <div class="row form-date">
                            <div class="col">
                                <label class="font-weight-bold">From Date</label>
                                <input class="form-control" placeholder="DD/MM/YY" readonly ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateSelect($event)" (click)="d.toggle()">
                            </div>
                        </div>
                        <div class="row mt-3 form-date"> 
                            <div class="col">    
                                <label class="font-weight-bold">To Date</label>
                                <input  class="form-control" placeholder="DD/MM/YY" readonly ngbDatepicker #d1 = "ngbDatepicker" (dateSelect)="onDateSelect1($event)" (click)="d1.toggle()">
                            </div>
                        </div>
                        <div style="color: red;" class="error">
                            {{dateError}}
                        </div>
                        <div class="error" *ngIf="isRequired">
                            To Date is required
                        </div>
                        <button type="submit" class="btn primary-btn mt-3 mb-4" (click)="applyFilter()">Apply Filters</button>
                    </div>  
                </div>
                <button class="all-status  profile flex flex-ce align-items-center">
                    <span class="status-icon">
                        <img src="../../../../../../../assets/imgs/all-status.svg" alt="">
                    </span>
                    <ng-select [clearable]="false" (change)="selectOrderStatus($event)" [(ngModel)]="statusObj" appendTo="body" [searchable]="false" placeholder="All Status">
                        <ng-option class="capitalize" *ngFor="let status of statusList" [value]="status.orderStatusId">
                          {{status.name}}
                        </ng-option>
                    </ng-select>
                </button>
            </div> -->
        </div>
        <!-- order-list in mobile -view -->
        <!-- <div class="order-mobile-viwe" *ngFor = "let order of ledgerData">
            <div class="head-title flex items-center flex-sb">
                <div class="left-part">
                    <ul class="inr-wrapper flex items-center orders-id">
                        <li class="label">Order Id #</li>
                        <li class="value">
                            {{order.orderId}}
                        </li>
                    </ul>
                    <ul class="inr-wrapper flex items-center">
                        <li class="label">Sender</li>
                        <li class="value">{{order.createdDate | date : 'dd/MM/yy'}}</li>
                    </ul>
                </div>
                <div class="right-part">
                    <button class="flex items-center flex-sb print-btn border-btn" (click)="downloadInvoice(order.vendorOrderId)">
                        <img src="../../../../../../../assets/images/print-icon.svg" alt=""> 
                        Print Label
                    </button>
                </div>
            </div>
            <div class="data-content">
                <ul class="inr-wrapper head-title flex items-center flex-sb">
                    <li class="label">Sender</li>
                    <li class="value">{{order.customerFirstName}}</li>
                </ul>
                <ul class="inr-wrapper head-title flex items-center flex-sb">
                    <li class="label">Reciever</li>
                    <li class="value">{{order.productName}}</li>
                </ul>
                <ul class="inr-wrapper head-title flex items-center flex-sb">
                    <li class="label">Source</li>
                    <li class="value">{{order.currencySymbolLeft}} {{order.total | number : '1.2-2' - order.discountAmount}} {{order.currencySymbolRight}}</li>
                </ul>
                <ul class="inr-wrapper head-title flex items-center flex-sb">
                    <li class="label">Amount</li>
                    <li class="value">{{order.currencySymbolLeft}} {{order.total | number : '1.2-2' - order.discountAmount}} {{order.currencySymbolRight}}</li>
                </ul>
                <ul class="inr-wrapper head-title flex items-center flex-sb">
                    <li class="label">Bought By</li>
                    <li class="value">{{order.productSellType}}</li>
                </ul>
               <div class="order-status-wrapper flex items-center flex-sb">
                <div class="left-part label">
                    Order Status
                </div>
                <div class="order-status flex items-center flex-sb Processing">
                    <span class="update-status"></span>
                   <span class="text">
                    <ng-select id="drpdwn" [clearable]="false" [searchable]="false" [items]="statusList" [(ngModel)]="order.subOrderStatusId" bindLabel="name" bindValue="orderStatusId" (change)="statusChange(order)"></ng-select>
                   </span> 
                    <span class="down-icon"><svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="icon" d="M0.898373 1.90128L5.42593 6.82131C5.73718 7.15955 6.26275 7.15955 6.574 6.82131L11.1016 1.90128C11.4418 1.53159 11.1855 0.924988 10.6892 0.924988L1.31083 0.924988C0.814447 0.924988 0.558209 1.53159 0.898373 1.90128Z" fill="#000819"/>
                    </svg></span>
                </div>
               </div>
            </div>
        </div> -->
       <!-- start of table-list -->
        <div class="table-data-details">
        <table class="table-list">
            <thead>
                <tr>
                    <th>
                        <span class="check-action">
                            <input type="checkbox" name="" (change)="selectAll($event)">
                        </span>
                    </th>
                    <th>Order Id #</th>
                    <th>Sender</th>
                    <th>Reciever</th>
                    <th>Date</th>
                    <th class="product">Source</th>
                    <th>Amount($)</th>
                    <th>Ledger type</th>
                    <th class="bought">Balance($)</th>
                    <th>Transaction Type</th>
                    <!-- <th>Transaction ID</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor = "let order of ledgerData">
                    <!-- <td class="order-id">
                        <a href="javascript:void(0)" [routerLink]="['order-details/', order.vendorOrderId]">{{order.orderId}}</a>
                    </td> -->
                    <td>
                        <span class="check-action">
                            <input type="checkbox" [checked]="this.Ids.includes(order?.orderId)" name="" id="chk_{{order?.orderId}}" (change)="addProductToArr($event, order?.orderId)">
                        </span>
                    </td>
                    <td *ngIf="order.orderId !==0">
                        <a href="javascript:void(0)" (click)="goToOrders(order.vendorOrderID)">{{order.orderId ? order.orderId : '-'}}</a>
                    </td>
                    <td *ngIf="order.orderId === 0">
                        -
                    </td>
                    <td>
                        {{order.senderName}}
                    </td>
                    <td>
                        {{order.receiverName}}
                    </td>
                    <td>{{order.createdDate | date : 'mediumDate'}}</td>
                    <td>{{order.source}}</td>
                    <td>{{order.currencySymbolLeft}} {{order.amount | number : '1.2-2' - order.discountAmount}} {{order.currencySymbolRight}}</td>
                    <td>{{order.ledgerType}}</td>
                    <td>{{order.currencySymbolLeft}} {{order.balance | number : '1.2-2' - order.discountAmount}} {{order.currencySymbolRight}}</td>
                    <td class="type">{{order.transactionType}} </td>
                    <!-- <td>{{order.transactionId}} </td> -->
                </tr>
                <tr *ngIf="ledgerData.length === 0">
                    <td class="text-center" colspan="9999">
                        No transactions available
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
  <!-- end of table-list --> 
        <div class="showing-results-wrapper">
            <div class="showing-results flex items-center flex-sb">
                <div class="left-part">
                    Showing 1-10 of {{this.totalCount}} results
                </div>
            </div>
            <!-- end of container -->

            <!-- pagination -->
            <div class="pager">
                <app-pager (pageChange)="pageChange($event)" [pageSize]="limit" [currentPage]="currentPage" [counts]="this.totalCount"></app-pager>
            </div>
        </div>
    </div>
</section>
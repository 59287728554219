import { Pipe, PipeTransform } from '@angular/core';
import { IProduct } from '../../interfaces/interface';
import { environment } from '../../../../../environments/environment';
import { Cloudinary, CloudinaryImage } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';

@Pipe({
  name: 'imageDisplay'
})
export class ImageDisplayPipe implements PipeTransform {
  cld = new Cloudinary({
    cloud: {
      cloudName: environment.cloudName,
    }
  });

  transform(product: IProduct, width = 36, height = 36): CloudinaryImage {
    const previewImage = this.cld.image(environment.cloudFolder + "/" + product.containerName + "/" + product.image);
    return previewImage.resize(fill().width(width).height(height)).roundCorners(byRadius(4));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ScheduleStreamComponent } from './schedule-stream/schedule-stream.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScheduledStreamComponent } from './scheduled-stream/scheduled-stream.component';
import { StreamSandbox } from '../../../../core/stream/stream.sandbox';
import { StreamApiService } from '../../../../core/stream/stream.service';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import { EffectsModule } from '@ngrx/effects';
import { StreamEffects } from '../../../../core/stream/effects/stream.effect';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from '../../../../core/providers/interceptor/request.interceptor';
import { MyLiveStreamComponent } from './my-live-stream/my-live-stream.component';
import { IframecomponentComponent } from './iframecomponent/iframecomponent.component';
import {MatDialogModule} from '@angular/material/dialog';
import { WelcomeComponent } from './welcome/welcome.component';
import { CreateAuctionComponent } from './create-auction/create-auction.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddressmodalComponent } from './addressmodal/addressmodal.component';
import { CloudinaryModule } from "@cloudinary/ng";
import { SocialmediaComponent } from './socialmedia/socialmedia.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { PastShowsComponent } from './past-shows/past-shows.component';
import { GivewayWinnerComponent } from './giveway-winner/giveway-winner.component';
import { BlockedUsersModalComponent } from './blocked-users-modal/blocked-users-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddStreamComponent } from './add-stream/add-stream.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import {MatChipsModule} from '@angular/material/chips';
import { MediaService } from '../../../../core/media/media.service';
import { MediaPlayerComponent } from './mediaplayer/mediaplayer.component';
import { AddProductModule } from '../../../shared/components/add-product/add-product.module';
import { AddProductComponent } from '../../../shared/components/add-product/add-product.component';
import { ActionsDropdownModule } from '../../../shared/components/actions-dropdown/actions-dropdown.module';
import { FilterModule } from '../../../shared/components/filter/filter.module';
import { ImageDisplayModule } from '../../../shared/pipe/image-display/image-display.module';
import { ConfirmDeleteProductFromListModule } from '../../../shared/components/confirm-delete-product-from-list/confirm-delete-product-from-list.module';

export const routes = [
  {
    path: 'schedule-stream',
    component: ScheduleStreamComponent,
    data: {
      title: 'Schedule Stream',
    }
  },
  {
    path: 'add-product',
    component: AddProductComponent,
    data: {
      title: 'Add Product',
    }
  },
  {
    path: 'post-schedule-stream',
    component: ScheduledStreamComponent,
    data: {
      title: 'Stream',
    }
  },
  {
    path: 'my-live-stream',
    component: MyLiveStreamComponent,
    data: {
      title: 'Stream',
    }
  },
  {
    path: 'welcome',
    component: MyLiveStreamComponent,
    data: {
      title: 'Live Streams'
    }
  },
  {
    path: 'past-shows',
    component: PastShowsComponent,
    data: {
      title: 'Past shows'
    }
  },
  {
    path: 'add-stream',
    component: AddStreamComponent,
    data: {
      title: 'Add Stream'
    }
  },
  {
    path: 'edit-stream/:id',
    component: AddStreamComponent,
    data: {
      title: 'Edit Stream'
    }
  }
];

@NgModule({
  declarations: [
    ScheduleStreamComponent,
    ScheduledStreamComponent,
    MyLiveStreamComponent,
    IframecomponentComponent,
    WelcomeComponent,
    CreateAuctionComponent,
    AddressmodalComponent,
    SocialmediaComponent,
    PastShowsComponent,
    GivewayWinnerComponent,
    BlockedUsersModalComponent,
    AddStreamComponent,
    MediaPlayerComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatIconModule,
    MatAutocompleteModule,
    MatCardModule,
    MatTabsModule,
    EffectsModule.forFeature([StreamEffects]),
    MatDialogModule,
    NgbModule,
    CloudinaryModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatChipsModule,
    AddProductModule,
    ActionsDropdownModule,
    FilterModule,
    ImageDisplayModule,
    ConfirmDeleteProductFromListModule
  ],
  providers: [
    StreamSandbox, 
    StreamApiService, 
    StreamEffects,
    MediaService, 
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-US' }
  ]
})
export class StreamModule {}

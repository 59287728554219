import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionsDropdownComponent } from './actions-dropdown.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule
  ],
  declarations: [
    ActionsDropdownComponent,
  ],
  exports: [
    ActionsDropdownComponent
  ]
})

export class ActionsDropdownModule { }
import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { StreamApiService } from '../../../../../core/stream/stream.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
import { Cloudinary } from "@cloudinary/url-gen";
import { crop } from "@cloudinary/url-gen/actions/resize";
import { SocialmediaComponent } from '../socialmedia/socialmedia.component';
import { MatDialog } from '@angular/material/dialog';
import { MediaPlayerComponent } from '../mediaplayer/mediaplayer.component';
import { IActionDropdownOption } from '../../../../shared/components/actions-dropdown/interfaces';
import { getLocale } from '../../../../../core/shared/utility/utilityHelpers';

@Component({
  selector: 'app-past-shows',
  templateUrl: './past-shows.component.html',
  styleUrls: ['./past-shows.component.scss']
})

export class PastShowsComponent implements OnInit {

  actions: IActionDropdownOption[] = [
    {
      icon: 'download',
      title: 'Download',
      actionType: 'download'
    },
    {
      icon: 'share',
      title: 'Share',
      actionType: 'share'
    },
    {
      icon: 'delete',
      title: 'Delete',
      actionType: 'delete'
    }
  ]; 
  userStreams: any[] = [];
  public bucketName = environment.videoBucketName;
  public videoUrl = environment.baseBucketUrl;
  public vendorPrefixId = JSON.parse(localStorage.getItem('vendorUserDetails')).vendorPrefixId;
  public prefixId = this.vendorPrefixId + '/';
  currentFolder: string = '';
  streamUUID : string = '';
  cld = new Cloudinary({
    cloud: {
      cloudName: environment.cloudName
    }
  });
  private filterParams: Params = {};
  public labelUrl: string = environment.labelUrl;
  /*
    transactionFilter = [
      { 'id' : 1, 'title' : 'Today' },
      { 'id' : 2, 'title' : 'Last 7 days' },
      { 'id' : 3, 'title' : 'Last 30 days' }
    ]
    transactionFilterId : any = '';
  */
  videoDownloadUrl: string = "https://dev-blazing-card-agora-streams.s3.amazonaws.com/directory1/463f341433456e084132229ac04cf30e_stream_02e3d043-1af9-4ca7-8d78-283195b58b1e__uid_s_10001__uid_e_video.m3u8";
  player: any;

  constructor(
    private service : StreamApiService, 
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) 
  {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
   }

  ngOnInit(): void {
    const asideBarElement = document.getElementsByClassName('aside-bar')[0] as HTMLElement;
    if (asideBarElement) {
      asideBarElement.parentElement.style.display = 'block'
    }
    this.getMyPastStreams();
    this.currentFolder = this.vendorPrefixId + '/';
  }

  prepareStreamData(stream: any) {
    const locale = getLocale();
    const format = new Intl.DateTimeFormat(locale, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    stream.scheduleDate = format.format(new Date(Number(stream.scheduletimeStamp)));

    const previewVideoUrl = environment.cloudFolder + "/"+ this.currentFolder + "/" + stream.preview_image;
    stream.preview_video = this.cld.video(previewVideoUrl.split('.')[0]);
    stream.preview_video.resize(crop().width(88).height(125));
    stream.preview_image = this.cld.image(previewVideoUrl + '.png');
    stream.preview_image.resize(crop().width(88).height(125));
    return stream;
  }

  getMyPastStreams(): void {
    let params: any = {
      ...this.filterParams,
      user_id: localStorage.getItem('vendorUserDetails') ? JSON.parse(localStorage.getItem('vendorUserDetails')).id : 0,
      streamType: 2
    };
    this.service.getStreamList(params).subscribe((result: any) => {
      if (result) {
        this.userStreams = result.data;        
        this.userStreams.forEach((stream) => this.prepareStreamData(stream));
      } 
      else {
        this.userStreams = [];
      }
    });
  }

  deleteStream(stream: any): void {
    this.service.deleteStream(stream.uuid).subscribe((result : any) => {
      if (result && result.status === 1) {
        this.toastr.success(result.data);
        this.getMyPastStreams();
      } else {
        this.toastr.error(result.data);
      }
    })
  }

  socialMedia(stream : any) {
    this.dialog.open(SocialmediaComponent, {
      data: {
        id : stream.id,
        uuid : stream.uuid
      }
    }).afterClosed().subscribe(() => {
      
    });
  }

  /**
   * @method -- download Stream
   * @param streamId 
   */
  downloadStream(video_url: string) {
   window.open('https://' + this.bucketName + '.' + this.videoUrl + '/' + video_url, '_blank');
   this.videoDownloadUrl = 'https://' + this.bucketName + '.' + this.videoUrl + '/' + video_url;

    this.dialog.open(MediaPlayerComponent, {
      width: '900px',
      data: {
        url : this.videoDownloadUrl
      }
    });
  }

  /**
  * @method -- Change Event
  * @param event 
  selectChangeHandler(event: any) {
    this.transactionFilterId = event.target.value;
    if(this.startDate && this.endDate) {
      const params: any = {};
      params.streamType = 2;
      params.keyword = this.keyword ? this.keyword : '';
      params.startDate = this.startDate.getFullYear() + '-' + (this.startDate.getMonth()+ 1) + '-' + this.startDate.getDate();
      params.endDate = this.endDate.getFullYear() + '-' + (this.endDate.getMonth()+ 1) + '-' + this.endDate.getDate();
      params.filter = this.transactionFilterId ? this.transactionFilterId : null;
      this.service.getStreamList(params).subscribe((result: any) => {
        if (result) {
          this.userStreams = result.data;
          this.userStreams.forEach(this.prepareStreamData);
        }
        else {
          this.userStreams = [];
        }
      });
    }
    else {
      const params: any = {};
      params.streamType = 2;
      params.keyword = this.keyword ? this.keyword : '';
      params.startDate = '';
      params.endDate = '';
      params.filter = this.transactionFilterId ? this.transactionFilterId : null;
      this.service.getStreamList(params).subscribe((result: any) => {
        if (result) {
          this.userStreams = result.data;          
          this.userStreams.forEach(this.prepareStreamData);
        }
        else {
          this.userStreams = [];
        }
      });
    }
  }
  */

  onAction({action, stream}: {action: IActionDropdownOption, stream: any}): void {
    switch(action.actionType) {
    case 'download':
      this.downloadStream(stream.video_name);
      return;
    case 'share':
      this.socialMedia(stream);
      return;
    case 'delete':
      this.deleteStream(stream);
      return;
    }
  }

  applyFilter(filterParams: Params): void {
    console.log({filterParams});
    this.filterParams = filterParams;
    this.getMyPastStreams();
  }
}
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelpCenterApiService } from './help-center.service';
import { RaiseRequestComponent } from './raise-request/raise-request.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})

export class HelpCenterComponent implements OnInit, OnDestroy {

  user_id : any;
  limit = 10;
  offset =0;
  public keyword : string = '';
  ticketId : string = '';
  subject : string = '';
  startDate : Date;
  endDate : Date;
  conversation : any;
  conversations : any[] = [];

  afterClosedSubscription = Subscription.EMPTY;

  constructor(
    public dialog: MatDialog,
    private service: HelpCenterApiService,
    public router: Router,
    private toaster: ToastrService,
    private changeDetectRef: ChangeDetectorRef,
  ) {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  ngOnInit(): void {
    this.user_id = JSON.parse(localStorage.getItem('vendorUserDetails')).vendorId;
    this.getAllConversations();
  }

  ngOnDestroy(): void {
    this.afterClosedSubscription.unsubscribe();
  }

  /**
  * @method -- get All Conversations List
  */
  getAllConversations() {
    let params = {
      user_id : this.user_id,
      limit : this.limit,
      offset : this.offset,
    } as any;

    if (this.ticketId) {
      params.ticketId = this.ticketId;
    }

    if (this.subject) {
      params.subject = this.subject;
    }

    if (this.startDate) {
      params.StartDate = this.startDate;
    }

    if (this.endDate) {
      params.endDate = this.endDate
    }

    this.service.GetAllConversations(params).subscribe((result) => {
      if (result && result.data) {
        this.conversations = result.data;
        this.changeDetectRef.detectChanges();
      }
    });
  }

  /**
  * @method -- search Order
  * @param key 
  */
  searchTicket(key) {
    if((isNaN(key))) {
      this.ticketId = '';
      this.subject = key;
      this.getAllConversations();
    }
    if((!isNaN(key))) {
      this.ticketId = key;
      this.subject = '';
      this.getAllConversations();
    }
  }

  /**
  * @method -- apply DateRange Filter 
  */
  applyDateRangeFilter() {
    if(!this.startDate && !this.endDate) {
      this.toaster.error('Please Provide Start Date and End Date');
    }
    else if(!this.startDate) {
      this.toaster.error('Choose Start Date First');
    }
    else if(!this.endDate) {
      this.toaster.error('Choose End Date');
    }
    else {
      let params = {
        user_id : this.user_id,
        limit : this.limit,
        offset : this.offset,
        ticketId : this.ticketId,
        subject : this.subject,
        StartDate : this.startDate.getFullYear() + '-' + (this.startDate.getMonth()+ 1) + '-' + this.startDate.getDate(),
        endDate : this.endDate.getFullYear() + '-' + (this.endDate.getMonth()+ 1) + '-' + this.endDate.getDate()
      }
      this.conversations = [];
      this.service.GetAllConversations(params).subscribe((data) => {
        if (data && data.status === 1) {
          this.conversations = data.data;
          this.conversations = data.data;
        } 
        else {
          this.conversations = [{ subject: "No Conversation Found" }];
        }
        this.changeDetectRef.detectChanges();
      });      
    }
  }

  /**
  * @method -- clear filter
  */
  clearFilter() {
    this.keyword = '';
    this.startDate = null;
    this.endDate = null;
    this.ticketId = '';
    this.subject = '';
    this.getAllConversations();
  }

  /**
  * @method -- raise request
  */
  raiseRequest()  {
    this.afterClosedSubscription.unsubscribe();
    this.afterClosedSubscription = this.dialog.open(RaiseRequestComponent, {
      data: {
        conversation: this.conversation
      }
    }).afterClosed().subscribe((data) => {
      if(data) {
        this.getAllConversations();
      }
    });
  }

  /**
   * @method -- goTo Details Page
   * @param item 
   */
  goToDetailsPage(item) {
    console.log(item);
    this.router.navigate(['/help-center/help-center-details/', item.Id]);
  }

  /**
   * @method -- reopen ticket
   * @param caht 
   */
  reOpen(chat) {

  }
}
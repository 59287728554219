import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../../../environments/environment';
import { Cloudinary } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';

@Component({
  selector: 'app-giveway-winner',
  templateUrl: './giveway-winner.component.html',
  styleUrls: ['./giveway-winner.component.scss']
})
export class GivewayWinnerComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  userData : any = {}
  currentUserImage : string
  public imageUrl = environment.imageUrl;
  public cloudName = environment.cloudName;
  public cloudFolder = environment.cloudFolder;
  cld: any;

  ngOnInit(): void {
    this.cld = new Cloudinary({
      cloud: {
        cloudName: this.cloudName,
      }
    });
    this.userData = this.data.userDetails;
    this.currentUserImage = this.cld.image(this.cloudFolder + "/"+ this.userData.customer_avatar_path.replace('/','') + "/" + this.userData.customer_avatar).resize(fill().width(30).height(30)).border(byRadius(50));
  }

}
